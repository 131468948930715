import { configureStore } from '@reduxjs/toolkit'
import { loginReducer } from './slices/api/auth/loginSlice'
import { storeReducer } from './slices/api/inner/storeSlice'
import { userReducer } from './slices/api/inner/userSlice'
import { dashboardReducer } from './slices/api/inner/dashboardSlice'
import { customerReducer } from './slices/api/inner/customerSlice'
import { reportReducer } from './slices/api/inner/reportSlice'
import { conversationReducer } from './slices/api/inner/conversationSlice'
import callStatusReducer from './slices/misc/callStatusSlice'
import callReducer from './slices/misc/callSlice'
import { twilioTokenReducer } from './slices/api/inner/twilioTokenSlice'
import { greetingReducer } from './slices/api/inner/greetingSlice'
import commonReducer from './slices/misc/commonStatesSlices'
import { holdCallReducer } from './slices/api/inner/holdSlice'

export const store = configureStore({
    reducer: {
        loginUser: loginReducer,
        store: storeReducer,
        user: userReducer,
        dashboard: dashboardReducer,
        customer: customerReducer,
        report: reportReducer,

        conversation: conversationReducer,
        callStatus: callStatusReducer,
        call: callReducer,
        common: commonReducer,
        twilioToken: twilioTokenReducer,
        greeting: greetingReducer,
        holdCall: holdCallReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})