import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

const Input = ({
    name,
    control,
    rules,
    defaultValue,
    label,
    className = "",
    type = "text",
    placeholder = "",
    icon
}) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <div className={`${className}`}>
                    <div className={`form-group position-relative`}>
                        {label && <label>{label}</label>}
                        <input type={type === "password" && showPassword ? "text" : type}  {...field} className={`form-control ${error && 'text-danger border-danger'}`} placeholder={placeholder} />
                        {icon && icon}
                    </div>
                    {error && (
                        <p className="text-danger">
                            {error.message || 'Error'}
                        </p>
                    )}
                </div>
            )
            }
        />
    )
}

export default Input
