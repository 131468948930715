import React from 'react'
import Button from '../../ui/Button'
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { DeleteStore, deleteStoreData } from '../../../store/slices/api/inner/storeSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreDeleteModal } from '../../../store/slices/misc/commonStatesSlices';

const StoreDeleteModal = ({
    storeId,
    setStoreId,
}) => {
    const dispatch = useDispatch();
    const modalState = useSelector(state => state.common.storeDeleteModal)

    const handleModalToggle = (value) => {
        dispatch(setStoreDeleteModal(value))
    };

    const afterAPICall = () => {
        setStoreId(null)
        handleModalToggle(false)
    };

    const beforeAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    storeId,
                },
            },
        };

    };

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: DeleteStore,
        setValueAction: deleteStoreData,
        isForm: false,
        afterAPICall,
        beforeAPICall,
    });

    const handleNullStoreId = () => {
        toast.error('Store Id not found.');
    }

    return (
        modalState ? <>
            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h5 className="text-center">Are you sure you want to delete this store?</h5>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>No</Button>
                            <Button isLoading={isLoading} className="btn-w" onClick={storeId ? handleSubmit : handleNullStoreId}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    )
}

export default StoreDeleteModal