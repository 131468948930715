import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { NAVIGATION } from "./Routes";
import LoginPage from "../../pages/auth/LoginPage";
import ForgetPassword from "../../pages/auth/ForgetPassword";
import Dashboard from "../../pages/inner/Dashboard";
import ManageStore from "../../pages/inner/ManageStore";
import ManageUser from "../../pages/inner/ManageUser";
import Conversations from "../../pages/inner/Conversations";
import Customers from "../../pages/inner/Customers";
import Report from "../../pages/inner/Report";
import PrivateRoutes from "./PrivateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { profile, selectLoggedInData, setLoginData } from "../../store/slices/api/auth/loginSlice";
import { useEffect } from "react";


const MainRoutes = () => {

    const dispatch = useDispatch();
    const authToken = localStorage.getItem("authToken");
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const loggedInData = useSelector(selectLoggedInData);
    

    const isLoggedIn = async () => {
        try {
            const response = await dispatch(profile());
            await dispatch(setLoginData(response?.payload?.data));
            if (
                pathname &&
                pathname === "/"
            ) {
                navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)
            }
        } catch (error) {
            await dispatch(setLoginData(null))
            console.log("Error in isLoggedIn : ", error);
        }
    };

    useEffect(() => {
        if (authToken && (!loggedInData || loggedInData === null)) {
            isLoggedIn();
        } else {
            if (
                pathname &&
                pathname === "/"
            ) {
                navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD)
            }
        }
    }, [authToken, loggedInData]);
    return (
        <Routes>

            {/* auth pages */}
            <Route path={NAVIGATION.LOGIN} element={<LoginPage />} />
            <Route path={NAVIGATION.AUTH_NAVIGATION.FORGET_PASSWORD} element={<ForgetPassword />} />


            {/* inner pages */}
            <Route element={<PrivateRoutes />}>
                <Route path={NAVIGATION.INNER_NAVIGATION.DASHBOARD} element={<Dashboard />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.MANAGE_STORE} element={<ManageStore />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.MANAGE_USER} element={<ManageUser />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.CONVERSATIONS} element={<Conversations />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.CUSTOMERS} element={<Customers />} />
                <Route path={NAVIGATION.INNER_NAVIGATION.REPORT} element={<Report />} />
            </Route>


        </Routes>
    )
}

export default MainRoutes