import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
};

export const FetchTwilioToken = createAsyncThunk(
    'admin/FetchTwilioToken',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/token`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "twilio Token fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const twilioTokenSlice = createSlice({
    name: 'twilioToken',
    initialState,
    reducers: {
        clearTwilioTokenData: state => {
            state.data = null;
        },
        setTwilioTokenData: (state, action) => {
            state.data = action.payload?.token;
        },
    },
});

export const twilioTokenReducer = twilioTokenSlice.reducer;
export const { clearTwilioTokenData, setTwilioTokenData } = twilioTokenSlice.actions;

export const selectTwilioTokenData = (state) => state.twilioToken.data;

