
import React from 'react'
import { NAVIGATION } from '../router/Routes';
import useApiRequest from '../../store/slices/hook/useApiRequest';
import { changePassword, clearLoginData } from '../../store/slices/api/auth/loginSlice';
import Input from '../ui/Input';
import Button from '../ui/Button';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setChangePasswordModal } from '../../store/slices/misc/commonStatesSlices';

const ChangePasswordModal = () => {
    const navigate = useNavigate();
    const modalState = useSelector(state => state.common.changePasswordModal)
    const dispatch = useDispatch();

    const handleModalToggle = (value) => {
        dispatch(setChangePasswordModal(value))
    };

    const afterAPICall = (response) => {
        handleModalToggle(false)
        navigate(NAVIGATION.LOGIN)
    }

    const { isLoading, control, handleSubmit, watch } = useApiRequest({
        apiAction: changePassword,
        setValueAction: clearLoginData,
        afterAPICall,
    });


    return (
        modalState ? <>
            <div
                className="modal fade show"
                aria-hidden="true"
                style={{
                    display: "block"
                }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Change Password</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => handleModalToggle(false)}
                                aria-label="Close"
                            >
                                <FaTimes />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Input
                                    label="Old Password"
                                    control={control}
                                    name="oldPassword"
                                    type="password"
                                    className="mb-3"
                                    rules={{
                                        required: "this filed is required"
                                    }}
                                />
                                <Input
                                    label="New Password"
                                    control={control}
                                    name="password"
                                    type="password"
                                    className="mb-3"
                                    rules={{
                                        required: "This field is required",
                                        // minLength: {
                                        //     value: 8,
                                        //     message: "Password must be at least 8 characters long"
                                        // },
                                        // pattern: {
                                        //     value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                        //     message: "Password must include at least one letter, one number, and one special character"
                                        // }
                                    }}
                                />
                                <Input
                                    label="Confirm Password"
                                    control={control}
                                    type="password"
                                    className="mb-3"
                                    name="confirmPassword"
                                    rules={{
                                        required: "This field is required",
                                        validate: (value) => {
                                            if (watch('password') !== value) {
                                                return "Passwords do not match"
                                            }
                                        },
                                    }}
                                />
                            </div>
                            <div className="modal-footer">
                                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                                <Button isLoading={isLoading} className="btn-w">Change Password</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null

    )
}

export default ChangePasswordModal