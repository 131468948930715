import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLoggedInData } from '../../store/slices/api/auth/loginSlice'
import { setChangePasswordModal, setLogoutModal, setUpdateProfileModal } from '../../store/slices/misc/commonStatesSlices';

const Header = () => {
    const loggedInData = useSelector(selectLoggedInData);
    const dispatch = useDispatch();

    const handleChangePasswordModalToggle = (value) => {
        dispatch(setChangePasswordModal(value))
    };
    
    const handleUpdateProfileModalToggle = (value) => {
        dispatch(setUpdateProfileModal(value))
    };

    const handleLogoutModalToggle = (value) => {
        dispatch(setLogoutModal(value))
    };
    return (
        <header>
            <ul className="right-side m-0 p-0 d-flex align-items-center justify-content-end">
                {/* Add Notification icon here if needed */}
                <li className="profile-col">
                    <div className="d-flex align-items-center profile-media" data-bs-toggle="dropdown">
                        <div className="img bg-light-primary text-primary rounded-3 d-flex align-items-center justify-content-center" style={{
                            height: "40px",
                            width: "40px",
                            fontSize: "20px",
                        }}>
                            {loggedInData?.userName?.charAt(0) || "A"}
                        </div>
                        <div className="flex-grow-1">
                            <span>{loggedInData?.userName
                                ? loggedInData?.userName
                                : "GS Admin"}</span>
                            <p className="mb-0 text-uppercase">{loggedInData?.role}</p>
                        </div>
                    </div>
                    <div className="dropdown-menu p-0">
                        <div className="main-list">
                            <ul>
                                <li>
                                    <div className="con">
                                        <div className="img bg-light-primary text-primary">
                                            {loggedInData?.userName?.charAt(0) || "A"}
                                        </div>
                                        <div className="txt">
                                            <span>{loggedInData?.userName
                                                ? loggedInData?.userName
                                                : "GS Admin"}</span>
                                            <small>{loggedInData?.email}</small>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <small className="d-flex justify-content-between w-100 align-items-center">
                                        <span><a href="javascript:void(0)" className="text-primary"  onClick={() => handleUpdateProfileModalToggle(true)}><i className="fa-solid fa-user"></i> Update Profile</a></span>
                                    </small>
                                </li>
                                <li>
                                    <small className="d-flex justify-content-between w-100 align-items-center">
                                        <span><a href="javascript:void(0)" className="text-primary" onClick={() => handleChangePasswordModalToggle(true)}><i className="fa-solid fa-unlock"></i> Change Password</a></span>
                                    </small>
                                </li>
                                <li>
                                    <small className="d-flex justify-content-between w-100 align-items-center">
                                        <span><a className="text-primary" href="javascript:void(0)" onClick={() => handleLogoutModalToggle(true)}><i className="fa-solid fa-unlock"></i> Logout</a></span>
                                    </small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </header>
    )
}

export default Header