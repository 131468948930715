import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    incomingCall: null,
    activeCall: null,
    device: null,
    mobileNumber: null,
}

export const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        setIncomingCall: (state, action) => {
            state.incomingCall = action.payload
        },
        clearIncomingCall: (state) => {
            state.incomingCall = null
        },
        
        setActiveCall: (state, action) => {
            state.activeCall = action.payload
        },
        clearActiveCall: (state) => {
            state.activeCall = null
        },

        setDevice: (state, action) => {
            state.device = action.payload
        },
        clearDevice: (state) => {
            state.device = null
        },

        setSelectedMobileNumber: (state, action) => {
            state.mobileNumber = action.payload
        },
        clearSelectedMobileNumber: (state) => {
            state.mobileNumber = null
        },

    },
})

export const { setIncomingCall, clearIncomingCall, setDevice, clearDevice, setActiveCall, clearActiveCall, setSelectedMobileNumber, clearSelectedMobileNumber } = callSlice.actions

export default callSlice.reducer