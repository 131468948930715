import { createSlice } from '@reduxjs/toolkit'
import { CALL_STATE } from '../../../utils/callStates'

const initialState = {
  value: CALL_STATE.OFFLINE,
}

export const callStatusSlice = createSlice({
  name: 'callStatus',
  initialState,
  reducers: {
    setCallStatus: (state, action) => {
      state.value = action.payload
    },
    clearCallStatusType: (state) => {
      state.value = null
    },  
  },
})

export const { setCallStatus, clearCallStatusType } = callStatusSlice.actions

export default callStatusSlice.reducer