import React, { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import Button from '../../ui/Button'
import Input from '../../ui/Input'
import useApiRequest from '../../../store/slices/hook/useApiRequest'
import { selectSelectedContact, UpdateContact, updateContactData } from '../../../store/slices/api/inner/conversationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setEditNameModal } from '../../../store/slices/misc/commonStatesSlices'

const EditNameModal = () => {
    const selectedContact = useSelector(selectSelectedContact);
    const modalState = useSelector(state => state.common.editNameModal)

    const dispatch = useDispatch();
    const handleModalToggle = (value) => {
        dispatch(setEditNameModal(value))
    };

    const defaultValues = {
        customerName: selectedContact?.customerName || "",
    };

    const beforeAPICall = (formData) => {
        if (selectedContact?._id) {
            return {
                success: true,
                refactoredData: {
                    formData: {
                        ...formData,
                    },
                    query: {
                        contactId: selectedContact?._id,
                    },
                },
            };
        }
    };

    const afterAPICall = (response) => {
        handleModalToggle(false)
    }


    const { isLoading, control, handleSubmit, setValue } = useApiRequest({
        apiAction: UpdateContact,
        setValueAction: updateContactData,
        clearForm: false,
        defaultValues,
        beforeAPICall,
        afterAPICall,
    });


    useEffect(() => {
        if (selectedContact) {
            setValue("customerName", selectedContact?.customerName);
        }
    }, [selectedContact]);
    return (
        modalState ? <>
            <div className={`modal fade show`} aria-hidden="true" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit</h5>
                            <button type="button" className="btn-close" onClick={() => handleModalToggle(false)} aria-label="Close">
                                <FaTimes />
                            </button>
                        </div>
                        <form className="p-4" onSubmit={handleSubmit}>
                            <div className="modal-body p-0">
                                <div className="change-picture text-center">
                                    <figure className="mb-2">
                                        <img src="/images/user-img-big.png" alt="" />
                                        <span>
                                            <input type="file" id="upload" hidden />
                                            <label htmlFor="upload">
                                                <FaPencil />
                                            </label>
                                        </span>
                                    </figure>
                                    <span>Change Picture</span>
                                </div>


                                <Input
                                    label="Customer Name"
                                    control={control}
                                    name="customerName"
                                    className="mb-3"
                                    rules={{
                                        required: "this filed is required"
                                    }}
                                />

                            </div>
                            <div className="modal-footer">
                                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                                <Button isLoading={isLoading}>Save Changes</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    )
}

export default EditNameModal