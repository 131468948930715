import axios from 'axios';

const myAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

myAxios.interceptors.request.use(function (config) {
  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export default myAxios;
