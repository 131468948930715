import React from 'react'


const Button = ({ variant="primary", isLoading, children, className, onClick, type = "submit", loader = true, ...props }) => {
    return (
        <button type={type} onClick={onClick} disabled={isLoading} className={`btn ${variant === "primary" ? "btn-primary" : "btn-secondary"} d-flex align-items-center justify-content-center gap-2 ${className}`} {...props}>
            {(loader && isLoading) && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
            {children}
        </button>
    )
}

export default Button