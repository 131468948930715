import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    editNameModal: false,
    changePasswordModal: false,
    updateProfileModal: false,
    logoutModal: false,
    newMessageModal: false,
    customerDeleteModal: false,
    manageCustomerFormModal: false,
    storeDeleteModal: false,
    manageStoreFormModal: false,
    userDeleteModal: false,
    manageUserFormModal: false,
    reportDeleteModal: false,
    manageReportFormModal: false,
    selectedStore: null,
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setEditNameModal: (state, action) => {
            state.editNameModal = action.payload
        },
        setChangePasswordModal: (state, action) => {
            state.changePasswordModal = action.payload
        },
        setUpdateProfileModal: (state, action) => {
            state.updateProfileModal = action.payload
        },
        setLogoutModal: (state, action) => {
            state.logoutModal = action.payload
        },
        setNewMessageModal: (state, action) => {
            state.newMessageModal = action.payload
        },
        setCustomerDeleteModal: (state, action) => {
            state.customerDeleteModal = action.payload
        },
        setManageCustomerFormModal: (state, action) => {
            state.manageCustomerFormModal = action.payload
        },
        setStoreDeleteModal: (state, action) => {
            state.storeDeleteModal = action.payload
        },
        setManageStoreFormModal: (state, action) => {
            state.manageStoreFormModal = action.payload
        },
        setUserDeleteModal: (state, action) => {
            state.userDeleteModal = action.payload
        },
        setManageUserFormModal: (state, action) => {
            state.manageUserFormModal = action.payload
        },
        setReportDeleteModal: (state, action) => {
            state.reportDeleteModal = action.payload
        },
        setManageReportFormModal: (state, action) => {
            state.manageReportFormModal = action.payload
        },
         setSelectedStore: (state, action) => {
            state.selectedStore = action.payload
        },
        clearSelectedStore: (state) => {
            state.selectedStore = null
        },
    },
})

export const {
    setEditNameModal,
    setChangePasswordModal,
    setUpdateProfileModal,
    setLogoutModal,
    setNewMessageModal,
    setCustomerDeleteModal,
    setManageCustomerFormModal,
    setStoreDeleteModal,
    setManageStoreFormModal,
    setUserDeleteModal,
    setManageUserFormModal,
    setReportDeleteModal,
    setManageReportFormModal,
    setSelectedStore,
    clearSelectedStore
} = commonSlice.actions

export default commonSlice.reducer