import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./components/router/MainRouter";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Device } from "@twilio/voice-sdk";
import { useDispatch, useSelector } from "react-redux";
import { CALL_STATE } from "./utils/callStates";
import useApiRequest from "./store/slices/hook/useApiRequest";
import { FetchTwilioToken, setTwilioTokenData } from "./store/slices/api/inner/twilioTokenSlice";
import { useEffect, useRef, useState } from "react";
import { setCallStatus } from "./store/slices/misc/callStatusSlice";
import { clearActiveCall, clearIncomingCall, setActiveCall, setDevice, setIncomingCall } from "./store/slices/misc/callSlice";
import IncomingModal from "./components/inner/conversations/IncomingModal";
import { selectLoggedInData } from "./store/slices/api/auth/loginSlice";
import { addChatData, OnCallConnected, selectSelectedContact } from "./store/slices/api/inner/conversationSlice";
import DialerModal from "./components/inner/conversations/DialerModal";
import { HoldCall, setHoldCallData } from "./store/slices/api/inner/holdSlice";

function App() {
  const device = useRef(null);

  const dispatch = useDispatch();
  const incomingCall = useSelector(state => state.call.incomingCall);
  const callState = useSelector(state => state.callStatus.value);
  const activeCall = useSelector(state => state.call.activeCall);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const loggedInData = useSelector(selectLoggedInData);
  const selectedContact = useSelector(selectSelectedContact);
  const selectedMobileNumber = useSelector(state => state.call.mobileNumber)
  const [phoneNumber, setPhoneNumber] = useState("");

  const fetchToken = async (response) => {
    try {

      device.current = new Device(response.token, {
        codecPreferences: ['ashburn', 'opus', 'pcmu']
      });

      device.current.register();
      device.current.on("registered", () => {
        console.log("Device registered");
        dispatch(setCallStatus(CALL_STATE.READY));
      });

      device.current.on('incoming', (call) => {
        console.log('Incoming call:', call);
        if (call?.parameters?.To === loggedInData?.storeId?.twilioPhone) {
          call?.disconnect();
        } else {
          dispatch(setIncomingCall(call));
          dispatch(setActiveCall(call));
          dispatch(setCallStatus(CALL_STATE.INCOMING));
        }

        call.on('accept', () => {
          console.log('Call accepted');
          dispatch(setCallStatus(CALL_STATE.ACCEPTED));
        });

        call.on('reject', () => {
          console.log('Call rejected');
          dispatch(setCallStatus(CALL_STATE.READY));
        });
        call.on('cancel', () => {
          console.log('Call canceled');
          dispatch(clearIncomingCall());
          dispatch(clearActiveCall());
          dispatch(setCallStatus(CALL_STATE.READY));
        });

        call.on('disconnect', () => {
          console.log('Call disconnected');
          dispatch(clearIncomingCall());
          dispatch(clearActiveCall());
          dispatch(setCallStatus(CALL_STATE.READY));
        });
      });
    } catch (error) {
      console.error('Error fetching token or initializing device:', error);
    }
  };

  const { handleSubmit: fetchTwilioTokenDate } = useApiRequest({
    apiAction: FetchTwilioToken,
    setValueAction: setTwilioTokenData,
    successMessage: false,
    errorMessage: false,
    isForm: false,
    afterAPICall: fetchToken,
  });

  const handleHangup = () => {
    if (activeCall) {
      activeCall.disconnect();
      dispatch(setCallStatus(CALL_STATE.READY));
      dispatch(clearIncomingCall());
    }
  };
  const handleMuteToggle = () => {
    if (activeCall) {
      activeCall.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };
  const handleHoldToggle = () => {
    if (activeCall) {
      console.log(activeCall)
      handleHoldCall();
    }
  };

  const handleAccept = () => {
    if (activeCall) {
      activeCall.accept();
    }
  };
  const handleReject = () => {
    if (activeCall) {
      activeCall.reject();
      dispatch(setCallStatus(CALL_STATE.READY));
      dispatch(clearIncomingCall());
    }
  };

  const beforeSubmitAPICall = () => {
    return {
      success: true,
      refactoredData: {
        formData: {
          CallSid: activeCall?.parameters?.CallSid,
          contactId: selectedContact?._id,
          customerId: selectedContact?.customerId?._id,
          userId: loggedInData?.role === 'admin' ? selectedContact?.customerId?.assignedUserId : loggedInData?._id,
        },
      },
    };
  };

  const { handleSubmit } = useApiRequest({
    apiAction: OnCallConnected,
    setValueAction: addChatData,
    isForm: false,
    successMessage: false,
    beforeAPICall: beforeSubmitAPICall,
    afterAPICall: (response) => {
      setIsOnHold(!isOnHold);
    }
  });

  const beforeHandleHoldCall = () => {
    return {
      success: true,
      refactoredData: {
        query: {
          CallSid: activeCall?.parameters?.CallSid,
        }
      },
    };
  };

  const { handleSubmit: handleHoldCall } = useApiRequest({
    apiAction: HoldCall,
    setValueAction: setHoldCallData,
    isForm: false,
    successMessage: false,
    beforeAPICall: beforeHandleHoldCall,
  });

  const logEvent = (eventName, status) => () => {
    console.log(`${eventName} event triggered`);
    if (status) dispatch(setCallStatus(status));
  };

  const attachEventListeners = (call) => {
    if (!call) {
      console.error('No call object available to attach event listeners');
      return;
    }

    call.on('connected', logEvent('connected', CALL_STATE.READY));
    call.on('queued', logEvent('queued', CALL_STATE.QUEUED));
    call.on('ringing', logEvent('ringing', CALL_STATE.RINGING));
    call.on('transportClose', logEvent('transportClose', CALL_STATE.READY));
    call.on('in-progress', logEvent('in-progress', CALL_STATE.IN_PROGRESS));
    call.on('completed', logEvent('completed', CALL_STATE.READY));
    call.on('accept', (callDetails) => {
      dispatch(setActiveCall(callDetails));
      console.log(`accept event triggered`);
      dispatch(setCallStatus(CALL_STATE.ACCEPTED));
      handleSubmit();
    });
    call.on('disconnect', () => {
      console.log('Call disconnected');
      dispatch(clearIncomingCall());
      dispatch(clearActiveCall());
      dispatch(setCallStatus(CALL_STATE.READY));
    });
    call.on('cancel', logEvent('cancel', CALL_STATE.READY));
    call.on('reject', logEvent('rejected', CALL_STATE.READY));

    call.on('error', (error) => {
      console.error('Call error:', error);
      dispatch(setCallStatus(CALL_STATE.READY));
    });

    const callEvents = Object.keys(call._events);
    console.log("Available events for the call object:", callEvents);
  };

  const handleCall = async (phoneNumber) => {
    try {
      const params = {
        To: phoneNumber,
        callerId: loggedInData?.role === "admin" ? '+61483900895' : loggedInData?.storeId?.twilioPhone, // TODO: add main Twilio number if needed.
      };

      if (device?.current) {
        console.log('Making a call with params:', params);
        const activeCallData = await device?.current.connect({ params });
        dispatch(setActiveCall(activeCallData));
        attachEventListeners(activeCallData);
      } else {
        dispatch(setCallStatus(CALL_STATE.READY));
        throw new Error('Device is not ready');
      }
    } catch (error) {
      dispatch(setCallStatus(CALL_STATE.READY));
      console.error('Error making call:', error);
    }
  };


  useEffect(() => {

    fetchTwilioTokenDate();

    return () => {
      if (device.current) {
        // Check device.current state before attempting to unregister
        if (device.current.state === 'registered') {
          device.current.disconnectAll();
          device.current.unregister();
        } else {
          console.warn('Device not registered; cannot unregister.');
        }
      }
    };

  }, [loggedInData]);

  useEffect(() => {
    if (selectedMobileNumber) {
      setPhoneNumber(selectedMobileNumber || "")
    }
  }, [selectedMobileNumber]);

  return (
    <BrowserRouter>
      <ToastContainer />
      <MainRoutes />
      {(incomingCall && activeCall?.parameters?.To !== loggedInData?.storeId?.twilioPhone) && <IncomingModal
        activeCall={activeCall}
        incomingCall={incomingCall}
        handleAccept={handleAccept}
        handleReject={handleReject}
        callState={callState}
        handleHangup={handleHangup}
        handleMuteToggle={handleMuteToggle}
        isMuted={isMuted}
        handleHoldToggle={handleHoldToggle}
        isOnHold={isOnHold}
      />}

      <DialerModal
        handleCall={handleCall}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        callState={callState}
        handleHangup={handleHangup}

        handleMuteToggle={handleMuteToggle}
        isMuted={isMuted}
        handleHoldToggle={handleHoldToggle}
        isOnHold={isOnHold}
      />
    </BrowserRouter>
  );
}

export default App;
