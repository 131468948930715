import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import Input from '../../ui/Input';
import Select from '../../ui/Select';
import Button from '../../ui/Button';
import { AddUser, UpdateUser, addUserData, updateUserData } from '../../../store/slices/api/inner/userSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useDispatch, useSelector } from 'react-redux';
import { FetchStores, selectStoreData, setStoreData } from '../../../store/slices/api/inner/storeSlice';
import { setManageUserFormModal } from '../../../store/slices/misc/commonStatesSlices';

const ManageUserForm = ({
  defaultData,
}) => {
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.common.manageUserFormModal)

  const handleModalToggle = (value) => {
    dispatch(setManageUserFormModal(value))
  };
  const storesData = useSelector(selectStoreData)
  const [storeOptions, setStoreOptions] = useState([{ label: "Select", value: null, selected: true }]);

  const defaultValues = {
    userName: defaultData?.userName || "",
    phone: defaultData?.phone || "",
    email: defaultData?.email || "",
    storeId: defaultData?.storeId || "",
  };

  const beforeUpdateAPICall = (formData) => {
    if (defaultData?._id) {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
          query: {
            userId: defaultData?._id,
          },
        },
      };
    } else {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
        },
      };
    }
  };

  const afterAPICall = (response) => {
    handleModalToggle(false)
  }

  const { isLoading, control, setValue, handleSubmit, reset } = useApiRequest({
    apiAction: defaultData?._id ? UpdateUser : AddUser,
    setValueAction: defaultData?._id ? updateUserData : addUserData,
    defaultValues,
    beforeAPICall: beforeUpdateAPICall,
    afterAPICall,
    clearForm: defaultData?._id ? false : true,
  });

  useEffect(() => {
    if (defaultData) {
      setValue("userName", defaultData?.userName);
      setValue("phone", defaultData?.phone);
      setValue("email", defaultData?.email);
      setValue("storeId", defaultData?.storeId);

    } else {
      setValue("userName", "");
      setValue("phone", "");
      setValue("email", "");
      setValue("storeId", "");

    }
  }, [defaultData]);


  // fetching store data
  const { handleSubmit: fetchStoreData } = useApiRequest({
    apiAction: FetchStores,
    setValueAction: setStoreData,
    successMessage: false,
    isForm: false,
  });

  useEffect(() => {
    if (!storesData) {
      fetchStoreData();
    }

    setStoreOptions(() => storesData ? [{ label: "Select", value: null, selected: true }, ...storesData?.map((item, idx) => {
      return {
        value: item._id,
        label: item.userName,
        selected: false,
      }
    })]
      : [{ label: "Select", value: null, selected: true }])
  }, [storesData])


  return (
    modalState ? <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{defaultData ? "Edit" : "Add New"} User</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleModalToggle(false)}
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Input
                  label="Name"
                  control={control}
                  name="userName"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
                <Select
                  label="Store"
                  control={control}
                  name="storeId"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                  options={storeOptions}
                />
                <Input
                  label="Phone Number"
                  control={control}
                  name="phone"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
                <Input
                  label="Email"
                  control={control}
                  name="email"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
              </div>
              <div className="modal-footer">
                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                <Button isLoading={isLoading} className="btn-w">{defaultData ? "Save Changes" : "Add"}</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
    </> : null
  )
}

export default ManageUserForm