import React, { useEffect } from 'react'
import { selectLoggedInData, setLoginData, updateProfile } from '../../store/slices/api/auth/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import useApiRequest from '../../store/slices/hook/useApiRequest';
import Button from '../ui/Button';
import Input from '../ui/Input';
import { FaTimes } from 'react-icons/fa';
import { setUpdateProfileModal } from '../../store/slices/misc/commonStatesSlices';

const UpdateProfileModal = () => {
    const loggedInData = useSelector(selectLoggedInData);
    const modalState = useSelector(state => state.common.updateProfileModal)
    const dispatch = useDispatch();

    const handleModalToggle = (value) => {
        dispatch(setUpdateProfileModal(value))
    };

    const defaultValues = {
        firstName: loggedInData?.firstName || "",
        lastName: loggedInData?.lastName || "",
        email: loggedInData?.email || "",
    };

    const afterAPICall = (response) => {
        handleModalToggle(false)
    }

    const { isLoading, control, setValue, handleSubmit, reset } = useApiRequest({
        apiAction: updateProfile,
        setValueAction: setLoginData,
        afterAPICall,
        defaultValues,
    });

    useEffect(() => {
        if (loggedInData) {
            setValue("firstName", loggedInData?.firstName);
            setValue("lastName", loggedInData?.lastName);
            setValue("email", loggedInData?.email);

        } else {
            setValue("firstName", "");
            setValue("lastName", "");
            setValue("email", "");
        }
    }, [loggedInData]);


    return (
        modalState ? <>
            <div
                className="modal fade show"
                style={{
                    display: 'block'
                }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Profile</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => handleModalToggle(false)}
                                aria-label="Close"
                            >
                                <FaTimes />
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <Input
                                    label="First Name"
                                    control={control}
                                    name="firstName"
                                    className="mb-3"
                                    rules={{
                                        required: "this filed is required"
                                    }}
                                />
                                <Input
                                    label="Last Name"
                                    control={control}
                                    name="lastName"
                                    className="mb-3"
                                    rules={{
                                        required: "this filed is required"
                                    }}
                                />
                                <Input
                                    label="Email"
                                    control={control}
                                    name="email"
                                    className="mb-3"
                                    rules={{
                                        required: "this filed is required"
                                    }}
                                />
                            </div>
                            <div className="modal-footer">
                                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                                <Button isLoading={isLoading} className="btn-w">Save Changes</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    )
}

export default UpdateProfileModal