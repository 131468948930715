import React, { useEffect, useState } from "react";
import { FaEye, FaPencil, FaTrashCan } from "react-icons/fa6";
import DashboardHOC from "../../components/hoc/DashboardHOC";
import Table from "../../components/ui/Table";
import ManageStoreForm from "../../components/inner/manage-store/ManageStoreForm";
import Button from "../../components/ui/Button";
import { FetchStores, selectStoreCount, selectStoreData, setStoreData } from "../../store/slices/api/inner/storeSlice";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { useDispatch, useSelector } from "react-redux";
import StoreDeleteModal from "../../components/inner/manage-store/StoreDeleteModal";
import TableSearch from "../../components/common/TableSearch";
import { setSelectedMobileNumber } from "../../store/slices/misc/callSlice";
import { setManageStoreFormModal, setSelectedStore, setStoreDeleteModal } from "../../store/slices/misc/commonStatesSlices";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";
import { useNavigate } from "react-router-dom";
import { NAVIGATION } from "../../components/router/Routes";

const ManageStore = () => {
    const [defaultData, setDefaultData] = useState(null);
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const storesData = useSelector(selectStoreData)
    const loggedInData = useSelector(selectLoggedInData)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDeleteModalToggle = (value, id) => {
        setSelectedStoreId(id);
        dispatch(setStoreDeleteModal(value))
    };

    const handleSetNumber = (number) => {
        dispatch(setSelectedMobileNumber(number));
    }

    const handleModalToggle = (value, data) => {
        setDefaultData(data)
        dispatch(setManageStoreFormModal(value))
    };

    // pagination code.
    const [currentPage, setCurrentPage] = useState(1);
    const totalCount = useSelector(selectStoreCount);
    const limit = 10;
    const [totalPages, setTotalPages] = useState(totalCount !== 0 ? Math.ceil(totalCount / limit) : 1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleItemClick = (path, data) => {
        dispatch(setSelectedStore({
            _id: data?._id,
            userName: data?.userName,
        }));
        navigate(path)
    }

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'userName',
                cell: info => info.getValue(),
            },
            // {
            //     accessorFn: row => row.phone,
            //     id: 'phone',
            //     cell: info => info.getValue(),
            //     header: () => <span>Store Phone</span>,
            // },
            {
                accessorKey: 'twilioPhone',
                header: () => 'Twilio Phone',
                cell: info => loggedInData?.role !== 'admin' ? <a href="javascript:void(0)" onClick={() => handleSetNumber(info?.getValue())} data-bs-toggle="modal" data-bs-target="#dialerModal">{info.getValue()}</a> : <a href="javascript:void(0)" style={{ cursor: "not-allowed" }}>{info.getValue()}</a>,
            },
            {
                accessorKey: 'twilioAccountSid',
                header: () => 'Twilio Sub Account',
            },
            {
                accessorKey: 'twilioShortCode',
                header: () => 'SMS Shortcode',
            },
            {
                id: "users",
                header: <span className='text-end'>
                    Users
                </span>,
                cell: (info) => {
                    return <>
                        <a href="javascript:void(0)" onClick={() => handleItemClick(NAVIGATION.INNER_NAVIGATION.MANAGE_USER, info.row.original)} className="text-blue">
                            <FaEye />
                        </a>
                    </>;
                },
            },
            {
                id: "conversation",
                header: <span className='text-end'>
                    Conversation
                </span>,
                cell: (info) => {
                    return <>
                        <a href="javascript:void(0)" onClick={() => handleItemClick(NAVIGATION.INNER_NAVIGATION.CONVERSATIONS, info.row.original)} className="text-orange">
                            <img src="/images/conv-icon.svg" alt="Conversation" />
                        </a>
                    </>;
                },
            },
            {
                id: "actions",
                header: <span className='text-end'>
                    Actions
                </span>,
                cell: (info) => {
                    return <>
                        <a href="javascript:void(0)" className="text-success" onClick={() => handleModalToggle(true, info.row.original)}>
                            <FaPencil />
                        </a>
                        <a href="javascript:void(0)" className="ms-2 text-danger" onClick={() => handleDeleteModalToggle(true, info.row.original?._id)}>
                            <FaTrashCan />
                        </a>
                    </>;
                },
            },
        ],
        []
    )

    const beforeAPICall = () => {
        return {
            success: true,
            refactoredData: {
                query: {
                    page: currentPage,
                    size: limit,
                },
            },

        }
    };

    const { handleSubmit: fetchStoreData } = useApiRequest({
        apiAction: FetchStores,
        setValueAction: setStoreData,
        successMessage: false,
        beforeAPICall,
        isForm: false,
    });

    useEffect(() => {
        fetchStoreData()
    }, [currentPage])

    return (
        <DashboardHOC title="Manage Stores">
            <div className="inner-wrapper">
                <div className="p-4">
                    <div className="row">
                        <TableSearch apiAction={FetchStores} setValueAction={setStoreData} />

                        <div className="col-auto ms-auto">
                            <Button
                                type="button"
                                onClick={() => handleModalToggle(true, null)}
                            >
                                ADD
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            {storesData && <Table
                                data={storesData}
                                columns={columns}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                totalPages={totalPages}
                            />}
                        </div>
                    </div>
                </div>

                <ManageStoreForm defaultData={defaultData} setDefaultData={setDefaultData} />
                <StoreDeleteModal storeId={selectedStoreId} setStoreId={setSelectedStoreId} />
            </div>
        </DashboardHOC>
    );
};

export default ManageStore;
