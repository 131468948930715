import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    chat: null,
    contact: null,
    selectedContact: null,
};

export const FetchChat = createAsyncThunk(
    'admin/FetchChat',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/chat/${data?.query?.contactId}`);
            return { success: true, message: "chat fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const AddChat = createAsyncThunk(
    'admin/AddChat',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/chat`, data?.formData);
            return { success: true, message: "chat added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchContact = createAsyncThunk(
    'admin/FetchContact',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/contact`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "contact fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchStoreContact = createAsyncThunk(
    'admin/FetchStoreContact',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/storecontact/${data?.query?.storeId}`);
            return { success: true, message: "contact fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const AddContact = createAsyncThunk(
    'admin/AddContact',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/contact`, data?.formData);
            return { success: true, message: "contact created successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const UpdateContact = createAsyncThunk(
    'admin/UpdateContact',
    async (data) => {
        try {
            const response = await myAxios.put(`admin/contact/${data?.query?.contactId}`, data?.formData);
            return { success: true, message: "contact updated successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const RemoveContact = createAsyncThunk(
    'admin/RemoveContact',
    async (data) => {
        try {
            const response = await myAxios.delete(`admin/contact/${data?.query?.contactId}`);
            return { success: true, message: "contact removed successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const OnCallConnected = createAsyncThunk(
    'admin/OnCallConnected',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/call`, data?.formData);
            return { success: true, message: "call details added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);


export const AddRecordingFile = createAsyncThunk(
    'admin/AddRecordingFile',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/record?callSid=${data?.query?.callSid}`);
            return { success: true, message: "recording added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        clearConversationData: (state) => {
            state.chat = null;
        },
        setConversationData: (state, action) => {
            state.chat = action.payload?.data;
        },
        addChatData: (state, action) => {
            const newData = action.payload?.data;
            state.chat = state?.chat ? [...state.chat, newData] : [newData];
        },

        clearContactData: state => {
            state.contact = null;
        },
        setContactData: (state, action) => {
            state.contact = action.payload?.data;
        },
        addContactData: (state, action) => {
            const newData = action.payload?.data;
            if (newData?.isExist) {
                state.selectedContact = newData
            } else {
                state.contact = state?.contact ? [...state.contact, newData] : [newData];
            }
        },

        updateContactData: (state, action) => {
            const newData = action.payload?.data;

            const updatedData = state?.data?.map((obj) => {
                if (obj._id === newData._id) {
                    return newData;
                } else {
                    return obj;
                }
            });
            state.contact = updatedData;
        },

        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload
        },
        clearSelectedContact: (state) => {
            state.selectedContact = null
        },

        handleAddRecordingFile: (state, action) => {
            const newData = action.payload?.data;
            if (state.chat) {
                const index = state.chat.findIndex(item => item.CallSid === newData.callsid);

                if (index !== -1) {
                    state.chat[index].recording = newData.data;
                } else {
                    state.chat.push({
                        CallSid: newData.callsid,
                        recording: newData.data
                    });
                }
            } else {
                state.chat = [{
                    CallSid: newData.callsid,
                    recording: newData.data
                }];
            }
        },

        removeContactSuccess: (state, action) => {
            const deletedId = action.payload?.data?._id;
            state.contact = state.contact.filter(obj => obj._id !== deletedId);
        },

    },
});

export const conversationReducer = conversationSlice.reducer;
export const { clearConversationData, setConversationData, clearContactData, setContactData, addContactData, handleAddRecordingFile, updateContactData, addChatData, setSelectedContact, clearSelectedContact, removeContactSuccess } = conversationSlice.actions;

export const selectConversationData = (state) => state.conversation.chat;
export const selectContactData = (state) => state.conversation.contact;
export const selectSelectedContact = (state) => state.conversation.selectedContact;
export const selectRemovingContact = (state) => state.conversation.removingContact;
