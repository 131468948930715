import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { clearLoginData } from '../api/auth/loginSlice';
import { clearStoreData } from '../api/inner/storeSlice';
import { clearUserData } from '../api/inner/userSlice';
import { clearDashboardData } from '../api/inner/dashboardSlice';
import { clearCustomerData } from '../api/inner/customerSlice';
import { clearReportData } from '../api/inner/reportSlice';
import { clearContactData, clearConversationData } from '../api/inner/conversationSlice';

const useApiRequest = ({
  apiAction,
  setValueAction,
  clearForm = true,
  defaultValues,
  afterAPICall,
  beforeAPICall,
  isForm = true,
  successMessage = true,
  errorMessage = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, reset, watch, getValues, setValue } = useForm({ defaultValues });
  const dispatch = useDispatch();

  const submitHandler = async (data) => {
    setIsLoading(true);
    try {
      const before = beforeAPICall ? beforeAPICall(data) : { success: true };
      if (before.success) {
        const result = await dispatch(apiAction(before?.refactoredData ? before?.refactoredData : data ? data : null));

        if (result?.payload?.success) {
          successMessage && toast.success(result?.payload?.message);
          setValueAction && (await dispatch(setValueAction(result.payload.data)));
          clearForm && reset();
          afterAPICall && afterAPICall(result.payload.data);
        } else {
          // console.log("1. <<<<<< ======================= >>>>>>", result?.payload?.message)
          if (result?.payload?.message === "Invalid token") {
            dispatch(clearLoginData());
            dispatch(clearStoreData());
            dispatch(clearUserData());
            dispatch(clearDashboardData());
            dispatch(clearCustomerData());
            dispatch(clearReportData());
            dispatch(clearContactData());
            dispatch(clearConversationData());
            localStorage.removeItem('authToken');
            localStorage.removeItem('authRole');
          }
          errorMessage && toast.error(result?.payload?.message);
        }
      } else {
        // console.log("2. <<<<<< ======================= >>>>>>", before.message)
        errorMessage && toast.error(before.message);
      }
    } catch (error) {
      // console.log("3. <<<<<< ======================= >>>>>>", error)
      toast.error('An error occurred while making the API request.');
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, control, handleSubmit: isForm ? handleSubmit(submitHandler) : submitHandler, watch, reset, getValues, setValue };
};

export default useApiRequest;
