import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
    totalCount: 0,
};

export const AddReport = createAsyncThunk(
    'admin/AddReport',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/report`, data?.formData);
            return { success: true, message: "report added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchReports = createAsyncThunk(
    'admin/FetchReports',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/report`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "report fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const UpdateReport = createAsyncThunk(
    'admin/UpdateReport',
    async (data) => {
        try {
            const response = await myAxios.put(`admin/report/${data?.query?.reportId}`,
                data?.formData
            );
            return { success: true, message: "report updated successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const DeleteReport = createAsyncThunk(
    'admin/DeleteReport',
    async (data) => {
        try {
            const response = await myAxios.delete(`admin/report/${data?.query?.reportId}`);
            return { success: true, message: "report deleted successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        clearReportData: state => {
            state.data = null;
        },
        setReportData: (state, action) => {
            state.data = action.payload?.data?.reports;
            state.totalCount = action.payload?.data?.totalCount;
        },
        addReportData: (state, action) => {
            const newData = action.payload?.data;
            state?.data ? state.data?.push(newData) : state.data = [newData];
        },
        updateReportData: (state, action) => {
            const newData = action.payload?.data;

            const updatedData = state.data.map((obj) => {
                if (obj._id === newData._id) {
                    return newData;
                } else {
                    return obj;
                }
            });
            state.data = updatedData;
        },

        handleAddRecordingFileInReport: (state, action) => {
            const newData = action.payload?.data;
            if (state.data) {
                const index = state.data.findIndex(item => item.CallSid === newData.callsid);

                if (index !== -1) {
                    state.data[index].recording = newData.data;
                } else {
                    state.data.push({
                        CallSid: newData.callsid,
                        recording: newData.data
                    });
                }
            } else {
                state.data = [{
                    CallSid: newData.callsid,
                    recording: newData.data
                }];
            }
        },

        deleteReportData: (state, action) => {
            const deletedId = action.payload?.data?._id;
            state.data = state.data.filter(obj => obj._id !== deletedId);
        },
    },
});

export const reportReducer = reportSlice.reducer;
export const { clearReportData, setReportData, addReportData, updateReportData, handleAddRecordingFileInReport, deleteReportData } = reportSlice.actions;

export const selectReportData = (state) => state.report.data;
export const selectReportCount = (state) => state.report.totalCount;
