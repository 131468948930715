import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
  data: null,
};

export const login = createAsyncThunk(
  'admin/login',
  async (formData) => {
    try {
      const response = await myAxios.post(`admin/login`, {
        email: formData?.email,
        password: formData?.password
      });

      const authToken = await response?.data?.data?.token;
      const authRole = await response?.data?.data?.role;
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('authRole', authRole);
      return { success: true, message: "logged in successfully!", data: response?.data };
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authRole');
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  },
);

export const profile = createAsyncThunk(
  'admin/profile',
  async () => {
    try {
      const response = await myAxios.get(`admin/profile`);
      return { success: true, message: "Profile data fetched successfully!", data: response?.data };
    } catch (error) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authRole');
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during getting profile data!' };
      }
    }
  },
);

export const changePassword = createAsyncThunk(
  'admin/changePassword',
  async (formData) => {
    try {
      const response = await myAxios.post("admin/changepassword", {
        password: formData.password,
        oldPassword: formData.oldPassword,
      });

      localStorage.removeItem('authToken');
      localStorage.removeItem('authRole');
      return { success: true, message: "Password changed successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during change password!' };
      }
    }
  },
);

export const updateProfile = createAsyncThunk(
  'admin/updateProfile',
  async (formData) => {
    try {
      const response = await myAxios.put("admin/updateProfile", {
        firstName: formData?.firstName || " ",
        lastName: formData?.lastName || " ",
        email: formData?.email.toLowerCase(),
      });
      return { success: true, message: "Profile data updated successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong during update profile data!' };
      }
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearLoginData: state => {
      state.data = null;
    },
    setLoginData: (state, action) => {
      state.data = action.payload?.data;
    },
  },
});

export const loginReducer = loginSlice.reducer;
export const { clearLoginData, setLoginData } = loginSlice.actions;

export const selectLoggedInData = (state) => state.loginUser?.data;
export const selectLoggedInRole = (state) => state.loginUser?.data?.role;
