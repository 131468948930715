import React, { useEffect, useState } from 'react'
import DashboardHOC from '../../components/hoc/DashboardHOC'
import { FaPencil, FaTrashCan } from 'react-icons/fa6';
import Table from '../../components/ui/Table';
import Button from '../../components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FetchCustomers, selectCustomerCount, selectCustomerData, setCustomerData } from '../../store/slices/api/inner/customerSlice';
import CustomerDeleteModal from '../../components/inner/customers/CustomerDeleteModal';
import ManageCustomerForm from '../../components/inner/customers/ManageCustomerForm';
import useApiRequest from '../../store/slices/hook/useApiRequest';
import formatDate from '../../utils/formatDate';
import TableSearch from '../../components/common/TableSearch';
import { setSelectedMobileNumber } from '../../store/slices/misc/callSlice';
import { setCustomerDeleteModal, setManageCustomerFormModal } from '../../store/slices/misc/commonStatesSlices';
import { selectLoggedInData } from '../../store/slices/api/auth/loginSlice';

const Customers = () => {
    const [defaultData, setDefaultData] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const customersData = useSelector(selectCustomerData)
    const loggedInData = useSelector(selectLoggedInData)
    const dispatch = useDispatch();

    const handleManageModalToggle = (value, data) => {
        setDefaultData(data);
        dispatch(setManageCustomerFormModal(value))
    };

    const handleModalToggle = (value, id) => {
        setSelectedCustomerId(id)
        dispatch(setCustomerDeleteModal(value))
    };

    const handleSetNumber = (number) => {
        dispatch(setSelectedMobileNumber(number));
    }
    // pagination code.
    const [currentPage, setCurrentPage] = useState(1);
    const totalCount = useSelector(selectCustomerCount);
    const limit = 10;
    const [totalPages, setTotalPages] = useState(totalCount !== 0 ? Math.ceil(totalCount / limit) : 1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'firstName',
                cell: info => info.getValue(),
            },
            {
                accessorFn: row => row.lastName,
                id: 'lastName',
                cell: info => info.getValue(),
                header: () => <span>Last Name</span>,
            },
            {
                accessorKey: 'phone',
                header: () => 'Phone Number',
                cell: info => loggedInData?.role !== 'admin' ? <a href="javascript:void(0)" onClick={() => handleSetNumber(info?.getValue())} data-bs-toggle="modal" data-bs-target="#dialerModal">{info.getValue()}</a> : <a href="javascript:void(0)" style={{ cursor: "not-allowed" }}>{info.getValue()}</a>,
            },
            {
                accessorKey: 'createdAt',
                header: () => 'Last Conversation',
                cell: info => formatDate(info.getValue()),
            },
            // {
            //     accessorFn: (row) => row?.assigneduserid?.userName || null,
            //     id: "assignedUser",
            //     header: () => 'Assigned User',
            //     cell: (info) => info.getValue() || "",
            // },
            {
                id: "actions",
                header: <span className='text-end'>
                    Actions
                </span>,
                cell: (info) => {
                    return <>
                        <a href="javascript:void(0)" className="text-success" onClick={() => handleManageModalToggle(true, info.row.original)}>
                            <FaPencil />
                        </a>
                        <a href="javascript:void(0)" className="ms-2 text-danger" onClick={() => handleModalToggle(true, info.row.original?._id)}>
                            <FaTrashCan />
                        </a>
                    </>;
                },
            },
        ],
        []
    )

    const beforeAPICall = () => {
        return {
            success: true,
            refactoredData: {
                query: {
                    page: currentPage,
                    size: limit,
                },
            },

        }
    };

    const { handleSubmit: fetchCustomerData } = useApiRequest({
        apiAction: FetchCustomers,
        setValueAction: setCustomerData,
        beforeAPICall,
        successMessage: false,
        isForm: false,
    });

    useEffect(() => {
        fetchCustomerData()
    }, [currentPage])

    return (
        <DashboardHOC title="Customers">
            <div className="inner-wrapper">

                <div className="p-4">
                    <div className="row gy-3 gy-lg-0">

                        <TableSearch apiAction={FetchCustomers} setValueAction={setCustomerData} />
                        <div className="col-auto ms-auto">
                            <Button
                                type="button"
                                onClick={() => handleManageModalToggle(true, null)}
                            >ADD</Button>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            {customersData && <Table
                                data={customersData}
                                columns={columns}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                totalPages={totalPages}
                            />}
                        </div>
                    </div>
                </div>

                <CustomerDeleteModal customerId={selectedCustomerId} setCustomerId={setSelectedCustomerId} />
                <ManageCustomerForm defaultData={defaultData} setDefaultData={setDefaultData} />
            </div>
        </DashboardHOC>
    )
}

export default Customers