import React from 'react'
import Button from '../../ui/Button'
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { toast } from 'react-toastify';
import { DeleteReport, deleteReportData } from '../../../store/slices/api/inner/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setReportDeleteModal } from '../../../store/slices/misc/commonStatesSlices';

const ReportDeleteModal = ({
    reportId,
    setReportId,
}) => {
    const dispatch = useDispatch();
    const modalState = useSelector(state => state.common.reportDeleteModal)

    const handleModalToggle = (value) => {
        dispatch(setReportDeleteModal(value))
    };

    const afterAPICall = () => {
        setReportId(null)
        handleModalToggle(false)
    };

    const beforeAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    reportId,
                },
            },
        };
    };

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: DeleteReport,
        setValueAction: deleteReportData,
        isForm: false,
        afterAPICall,
        beforeAPICall,
    });

    const handleNullReportId = () => {
        toast.error('Report Id not found.');
    }

    return (
        modalState ? <>

            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h5 className="text-center">Are you sure you want to delete this report?</h5>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>No</Button>
                            <Button isLoading={isLoading} className="btn-w" onClick={reportId ? handleSubmit : handleNullReportId}>Yes</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    )
}

export default ReportDeleteModal