export const NAVIGATION = {

    // *public pages
    LOGIN: "/",
   
    // *auth pages
    AUTH_NAVIGATION: {
      FORGET_PASSWORD: "/auth/forget-password",
    },
  
    INNER_NAVIGATION: {   
      // *inner pages
      DASHBOARD: "/dashboard",
      MANAGE_STORE: "/manage-store",
      MANAGE_USER: "/manage-user",
      CONVERSATIONS: "/conversations",
      CUSTOMERS: "/customers",
      REPORT: "/report",
    },
  };