import React, { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa';
import Input from '../../ui/Input';
import Button from '../../ui/Button';
import { AddStore, UpdateStore, addStoreData, updateStoreData } from '../../../store/slices/api/inner/storeSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setManageStoreFormModal } from '../../../store/slices/misc/commonStatesSlices';

const ManageStoreForm = ({
  defaultData,
}) => {
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.common.manageStoreFormModal)

  const handleModalToggle = (value) => {
    dispatch(setManageStoreFormModal(value))
  };

  const defaultValues = {
    userName: defaultData?.userName || "",
    phone: defaultData?.phone || "",
    twilioPhone: defaultData?.twilioPhone || "",
    twilioShortCode: defaultData?.twilioShortCode || "",
  };

  const beforeUpdateAPICall = (formData) => {
    if (defaultData?._id) {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
          query: {
            storeId: defaultData?._id,
          },
        },
      };
    } else {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
        },
      };
    }
  };

  const afterAPICall = (response) => {
    handleModalToggle(false)
  }

  const { isLoading, control, setValue, handleSubmit, reset } = useApiRequest({
    apiAction: defaultData?._id ? UpdateStore : AddStore,
    setValueAction: defaultData?._id ? updateStoreData : addStoreData,
    defaultValues,
    beforeAPICall: beforeUpdateAPICall,
    afterAPICall,
    clearForm: defaultData?._id ? false : true,
  });

  useEffect(() => {
    if (defaultData) {
      setValue("userName", defaultData?.userName);
      setValue("phone", defaultData?.phone);
      setValue("twilioPhone", defaultData?.twilioPhone);
      setValue("twilioShortCode", defaultData?.twilioShortCode);

    } else {
      setValue("userName", "");
      setValue("phone", "");
      setValue("twilioPhone", "");

      setValue("twilioShortCode", "");

    }
  }, [defaultData]);

  return (
    modalState ? <>

      <div
        className="modal fade show"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{defaultData ? "Edit" : "Add New"} Store</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleModalToggle(false)}
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Input
                  label="Name"
                  control={control}
                  name="userName"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
                {defaultData?._id && <>
                  {/* <Input
                  label="Store Phone"
                  control={control}
                  name="phone"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                /> */}
                  <Input
                    label="Twilio Phone"
                    control={control}
                    name="twilioPhone"
                    className="mb-3"
                    rules={{
                      required: "this filed is required"
                    }}
                  />

                  <Input
                    label="SMS Shortcode"
                    control={control}
                    name="twilioShortCode"
                    className="mb-3"
                  />
                </>}
              </div>
              <div className="modal-footer">
                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                <Button isLoading={isLoading} className="btn-w">{defaultData ? "Save Changes" : "Add"}</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
    </> : null
  )
}

export default ManageStoreForm