import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
    totalCount: 0,
};

export const AddUser = createAsyncThunk(
    'admin/AddUser',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/users`, data?.formData);
            return { success: true, message: "user added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchUsers = createAsyncThunk(
    'admin/FetchUsers',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/users`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "user fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchStoreUsers = createAsyncThunk(
    'admin/FetchStoreUsers',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/storeusers/${data?.query?.storeId}`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "user fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const UpdateUser = createAsyncThunk(
    'admin/UpdateUser',
    async (data) => {
        try {
            const response = await myAxios.put(`admin/users/${data?.query?.userId}`,
                data?.formData
            );
            return { success: true, message: "user updated successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const DeleteUser = createAsyncThunk(
    'admin/DeleteUser',
    async (data) => {
        try {
            const response = await myAxios.delete(`admin/users/${data?.query?.userId}`);
            return { success: true, message: "user deleted successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserData: state => {
            state.data = null;
        },
        setUserData: (state, action) => {
            state.data = action.payload?.data?.users;
            state.totalCount = action.payload?.data?.totalCount;
        },
        addUserData: (state, action) => {
            const newData = action.payload?.data;
            state?.data ? state.data?.push(newData) : state.data = [newData];
        },
        updateUserData: (state, action) => {

            const newData = action.payload?.data;

            const updatedData = state.data.map((obj) => {
                if (obj._id === newData._id) {
                    return newData;
                } else {
                    return obj;
                }
            });
            state.data = updatedData;
        },
        deleteUserData: (state, action) => {
            const deletedId = action.payload?.data?._id;
            state.data = state.data.filter(obj => obj._id !== deletedId);
        },
    },
});

export const userReducer = userSlice.reducer;
export const { clearUserData, setUserData, addUserData, updateUserData, deleteUserData } = userSlice.actions;

export const selectUserData = (state) => state.user.data;
export const selectUserCount = (state) => state.user.totalCount;
