import React from 'react'
import Button from '../ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearLoginData } from '../../store/slices/api/auth/loginSlice';
import { clearStoreData } from '../../store/slices/api/inner/storeSlice';
import { clearUserData } from '../../store/slices/api/inner/userSlice';
import { clearDashboardData } from '../../store/slices/api/inner/dashboardSlice';
import { clearCustomerData } from '../../store/slices/api/inner/customerSlice';
import { clearReportData } from '../../store/slices/api/inner/reportSlice';
import { clearContactData, clearConversationData } from '../../store/slices/api/inner/conversationSlice';
import { setLogoutModal } from '../../store/slices/misc/commonStatesSlices';

const LogoutModal = () => {
    const dispatch = useDispatch();
    const modalState = useSelector(state => state.common.logoutModal)

    const handleModalToggle = (value) => {
        dispatch(setLogoutModal(value))
    };

    const handleLogOut = () => {
        dispatch(clearLoginData());
        dispatch(clearStoreData());
        dispatch(clearUserData());
        dispatch(clearDashboardData());
        dispatch(clearCustomerData());
        dispatch(clearReportData());
        dispatch(clearContactData());
        dispatch(clearConversationData());
        localStorage.removeItem('authToken');
        localStorage.removeItem('authRole');
        handleModalToggle(false)
    }

    return (
        modalState ? <>
            <div className="modal fade show" style={{ display: "block" }} tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body">
                            <h5 className="text-center">Are you sure you want to sign out?</h5>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                            <Button type='button' className="btn-w"
                                onClick={handleLogOut}
                            >Signout</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    )
}

export default LogoutModal