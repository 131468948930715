import React from 'react'
import OnCall from './OnCall'
import { CALL_STATE } from '../../../utils/callStates'
import OnIncoming from './OnIncoming'
import Timer from './Timer';

const IncomingModal = ({
    activeCall,
    handleAccept,
    handleReject,
    callState,
    handleHangup,
    handleMuteToggle,
    isMuted,
    handleHoldToggle,
    isOnHold,
}) => {

    return (
        <>
            <div className="modal fade top-call show" id="incomingCall" tabIndex="-1" style={{
                display: 'block',
            }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h5 className="modal-title text-center mb-3">{activeCall?.parameters?.From ? activeCall?.parameters?.From : "Unknown"}</h5>
                            <div className="text-center">
                                <figure className="mb-2">
                                    <img src="/images/user-img-big.png" alt="" />
                                </figure>
                                <h6>{callState === CALL_STATE.ACCEPTED && <Timer />}</h6>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="calls-option">
                                {callState === CALL_STATE.ACCEPTED && <OnCall
                                    handleHangup={handleHangup}
                                    callState={callState}
                                    handleMuteToggle={handleMuteToggle}
                                    isMuted={isMuted}
                                    handleHoldToggle={handleHoldToggle}
                                    isOnHold={isOnHold}
                                />}

                                {callState === CALL_STATE.INCOMING && <OnIncoming
                                    handleAccept={handleAccept}
                                    handleReject={handleReject}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>
    )
}

export default IncomingModal