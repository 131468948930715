import React from 'react'

const ReportCheckbox = ({ name, checked, onChange }) => {
    return (
        <div className="form-check">
            <input
                type="checkbox"
                className={`form-check-input ${checked ? 'border-primary' : 'border-secondary'}`}
                id={name}
                checked={checked}
                onChange={onChange}
            />
             <label className="form-check-label" htmlFor={name}></label>
        </div>

    )
}

export default ReportCheckbox