import React from 'react'
import DashboardHOC from '../../components/hoc/DashboardHOC'
import RecentChatsUserList from '../../components/inner/conversations/RecentChatsUserList'
import ChatSection from '../../components/inner/conversations/ChatSection'
import EditNameModal from '../../components/inner/conversations/EditNameModal'
import SettingsModal from '../../components/inner/conversations/SettingsModal'
import NewMessageModal from '../../components/inner/conversations/NewMessageModal'
import { useSelector } from 'react-redux'

const Conversations = () => {
    const selectedStore = useSelector(state => state.common.selectedStore)

    return (
        <DashboardHOC title={selectedStore?._id ? selectedStore?.userName : "Conversations"}>
            <div className="inner-wrapper">
                <div className="p-0">
                    <div className="row g-0">
                        <RecentChatsUserList />
                        <ChatSection />

                        <EditNameModal />
                        <SettingsModal />
                        <NewMessageModal />
                    </div>
                </div>
            </div>
        </DashboardHOC>
    )
}

export default Conversations