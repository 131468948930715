import React from 'react'
import { FaEnvelope, FaPhone } from 'react-icons/fa6'

const DashboardStatistics = ({
    title,
    today,
    week,
    month,
    all,
}) => {
    return (
        <div className="col-md-6 mb-4">
            <div className="card">
                <h2 className="text-grey mb-3">Total {title}</h2>
                <div className="row">
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                        <div className={`icon ${title === "Calls" ? "bg-light-cyan" : "bg-light-blue"}`}>
                            {title === "Calls" ? <FaPhone className="text-cyan" /> : <FaEnvelope className="text-blue" />}
                        </div>
                        <ul className="total-list">
                            <li>
                                <div className="dash-con text-center">
                                    <h2>{today}</h2>
                                    <span>Today</span>
                                </div>
                            </li>
                            <li>
                                <div className="dash-con text-center">
                                    <h2>{week}</h2>
                                    <span>This Week</span>
                                </div>
                            </li>
                            <li>
                                <div className="dash-con text-center">
                                    <h2>{month}</h2>
                                    <span>Current Month</span>
                                </div>
                            </li>
                            <li>
                                <div className="dash-con text-center">
                                    <h2>{all}</h2>
                                    <span>All time</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardStatistics