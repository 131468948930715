import React, { useEffect, useState } from 'react'
import DashboardHOC from '../../components/hoc/DashboardHOC'
import { FaTrashCan } from 'react-icons/fa6'
import Table from '../../components/ui/Table';
import Button from '../../components/ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FetchReports, handleAddRecordingFileInReport, selectReportCount, selectReportData, setReportData } from '../../store/slices/api/inner/reportSlice';
import ReportDeleteModal from '../../components/inner/reports/ReportDeleteModal';
import ManageReportForm from '../../components/inner/reports/ManageReportForm';
import useApiRequest from '../../store/slices/hook/useApiRequest';
import formatDate from '../../utils/formatDate';
import ReportCheckbox from '../../components/inner/reports/ReportCheckbox';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FetchStores, selectStoreList, setStoreList } from '../../store/slices/api/inner/storeSlice';
import { AudioPlayer } from 'react-audio-player-component';
import { AddRecordingFile } from '../../store/slices/api/inner/conversationSlice';
import { setSelectedMobileNumber } from '../../store/slices/misc/callSlice';
import { setReportDeleteModal } from '../../store/slices/misc/commonStatesSlices';
import { selectLoggedInData } from '../../store/slices/api/auth/loginSlice';
import * as XLSX from 'xlsx';
import formatDuration from '../../utils/formatDuration';
import { BsFillTelephoneInboundFill, BsTelephoneOutboundFill, BsTelephoneXFill } from 'react-icons/bs';

const Report = () => {
  const [defaultData, setDefaultData] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const reportsData = useSelector(selectReportData);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const storesData = useSelector(selectStoreData)

  // Pagination code
  const [currentPage, setCurrentPage] = useState(1);
  const totalCount = useSelector(selectReportCount);
  const limit = 10;
  const [selectedStore, setSelectedStore] = useState('');
  const [totalPages, setTotalPages] = useState(totalCount !== 0 ? Math.ceil(totalCount / limit) : 1);
  const [selectedReportIds, setSelectedReportIds] = useState([]);
  const [callSid, setCallSid] = useState(null);
  const storeList = useSelector(selectStoreList);
  const loggedInData = useSelector(selectLoggedInData);
  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleModalToggle = (value, id) => {
    setSelectedReportId(id)
    dispatch(setReportDeleteModal(value))
  };

  const handleSetNumber = (number) => {
    dispatch(setSelectedMobileNumber(number));
  }

  const handleIndividualCheckboxChange = (reportId) => {
    if (selectedReportIds.includes(reportId)) {
      setSelectedReportIds(selectedReportIds.filter(id => id !== reportId));
    } else {
      setSelectedReportIds([...selectedReportIds, reportId]);
    }
  };

  const handleMasterCheckboxChange = (isChecked) => {
    if (isChecked) {
      const allReportIds = reportsData.map(report => report._id);
      setSelectedReportIds(allReportIds);
    } else {
      setSelectedReportIds([]);
    }
  };

  const beforeAddRecordingAPICall = (formData) => {
    return {
      success: true,
      refactoredData: {
        query: {
          callSid,
        },
      },
    };
  };

  const { handleSubmit: addRecordingURL } = useApiRequest({
    apiAction: AddRecordingFile,
    setValueAction: handleAddRecordingFileInReport,
    successMessage: false,
    beforeAPICall: beforeAddRecordingAPICall,
  });

  useEffect(() => {
    if (callSid) {
      addRecordingURL();
    }
  }, [callSid]);

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        cell: info => (
          <ReportCheckbox
            checked={selectedReportIds.includes(info.row.original._id)}
            onChange={() => handleIndividualCheckboxChange(info.row.original._id)}
          />
        ),
        header: () => (
          <ReportCheckbox
            checked={selectedReportIds.length === reportsData.length}
            onChange={(e) => handleMasterCheckboxChange(e.target.checked)}
          />
        ),
      },
      {
        accessorFn: row => row.phone,
        id: 'phoneIcon',
        cell: info => loggedInData?.role !== 'admin' ? <a href="javascript:void(0)" onClick={() => handleSetNumber(info?.getValue())} data-bs-toggle="modal" data-bs-target="#dialerModal" className={info.row.original?.result === "Pending" ? "text-red" : "text-primary"}>
          {info.row.original?.isSender ? <BsTelephoneOutboundFill /> : <BsFillTelephoneInboundFill />}
        </a> : <a href="javascript:void(0)" style={{ cursor: "not-allowed" }} className={info.row.original?.result === "Pending" ? "text-red" : "text-primary"}>{info.row.original?.isSender ? <BsTelephoneOutboundFill /> : <BsFillTelephoneInboundFill />}</a>,
        header: () => <span>Type</span>,
      },
      {
        accessorKey: 'phone',
        header: () => 'Phone Number',
        cell: info => loggedInData?.role !== 'admin' ? <a href="javascript:void(0)" onClick={() => handleSetNumber(info?.getValue())} data-bs-toggle="modal" data-bs-target="#dialerModal">{info.getValue()}</a> : <a href="javascript:void(0)" style={{ cursor: "not-allowed" }}>{info.getValue()}</a>,
      },
      {
        accessorKey: 'dateTime',
        header: () => 'Date / Time',
        cell: info => formatDate(info.getValue()),
      },
      {
        accessorKey: 'newRecording',
        header: "Recording",
        cell: info => <div>
          {info.row.original.result == 'Pending' ? null : <div className={`message`}>
            {info.row.original?.newRecording && <AudioPlayer
              src={info.row.original?.newRecording}
              minimal={true}
              width={350}
              trackHeight={40}
              barWidth={3}
              gap={1}
              visualise={true}
              backgroundColor="#fff"
              barColor="#959595"
              barPlayedColor="#5C61F2"
              skipDuration={2}
              showLoopOption={true}
              showVolumeControl={true}
              hideSeekBar={true}
              hideSeekKnobWhenPlaying={true}
            />}

            {info.row.original?.newRecording ? (
              <a href={info.row.original?.newRecording} target='_blank' download={`${info.row.original?._id}-recording.mp3`}>Download Audio</a>
            ) : (
              <a href="javascript:void(0)" onClick={() => setCallSid(info.row.original?.CallSid)}>Download Audio</a>
            )}

          </div>}
        </div>,
      },
      {
        accessorKey: 'action',
        header: () => 'Action',
      },
      {
        accessorKey: 'result',
        header: () => 'Result',
      },
      {
        accessorKey: 'length',
        header: () => 'Time',
        cell: info => formatDuration(info.getValue()),
      },
      {
        id: "actions",
        header: <span className='text-end'>
          Actions
        </span>,
        cell: (info) => {
          return (
            <>
              <a href="javascript:void(0)" className="ms-2 text-danger" onClick={() => handleModalToggle(true,
                info.row.original?._id)}>
                <FaTrashCan />
              </a>
            </>
          );
        },
      },
    ],
    [selectedReportIds, reportsData]
  );

  const beforeAPICall = () => {
    return {
      success: true,
      refactoredData: {
        query: {
          page: currentPage,
          size: limit,
        },
      },
    };
  };

  const optionArray = storeList?.data?.stores;
  useEffect(() => {
    dispatch(FetchStores());
  }, [dispatch]);

  const { handleSubmit: fetchReportData } = useApiRequest({
    apiAction: FetchReports,
    setValueAction: setReportData,
    successMessage: false,
    beforeAPICall,
    isForm: false,
  });

  useEffect(() => {
    fetchReportData();
  }, [currentPage]);


  const { handleSubmit: fetchStoreData } = useApiRequest({
    apiAction: FetchStores,
    setValueAction: setStoreList,
    successMessage: false,
    beforeAPICall,
    isForm: false,
  });

  useEffect(() => {
    fetchStoreData()
  }, [currentPage])

  const handleDownload = () => {
    const keysToInclude = {
      phone: 'Phone Number',
      dateTime: 'Date / Time',
      newRecording: 'Recording',
      action: 'Action',
      result: 'Result',
      length: 'Duration'
    };

    const dataToDownload = selectedReportIds.length > 0
      ? reportsData.filter(report => selectedReportIds.includes(report._id))
      : reportsData;

    const transformedData = dataToDownload.map(report => {
      const filteredReport = {};
      for (const key in keysToInclude) {
        if (report.hasOwnProperty(key)) {
          if (key === 'dateTime') {
            filteredReport[keysToInclude[key]] = formatDate(report[key]);
          } else if (key === 'length') {
            filteredReport[keysToInclude[key]] = formatDuration(report[key]);
          } else {
            filteredReport[keysToInclude[key]] = report[key];
          }
        }
      }
      return filteredReport;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData, { header: Object.values(keysToInclude) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reports');

    XLSX.writeFile(workbook, `${formatDate(new Date())}-Reports.xlsx`);
  };

  return (
    <DashboardHOC title="Reports">
      <div className="inner-wrapper">
        <div className="p-4">
          <div className="row gy-3">
            <div className="col-md-6 col-lg-2">
              <div className="store-col form-group">

                <select
                  className="form-control"
                  onChange={(e) => setSelectedStore(e.target.value)}
                  value={selectedStore}
                >
                  <option value="">Select Store</option>
                  {optionArray?.map(store => (
                    <option key={store._id} value={store._id}>{store.userName}</option>
                  ))}
                </select>

              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="store-col form-group">
                <input type="text" placeholder="Name" className="form-control" />
              </div>
            </div>
            <div className="col-md-12 col-lg-2">
              <div className="store-col form-group">
                <input type="text" placeholder="Phone Number" className="form-control" />
              </div>
            </div>
            <div className="col-md-12 col-lg-2">
              <div className="d-flex align-content-between col-start form-group align-items-center">
                <label htmlFor="startDate" className="date-label mb-0 me-2">Start Date</label>
                <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  className="form-control"
                  placeholderText="Select a date"
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-2">
              <div className="d-flex align-content-between col-start form-group align-items-center">
                <label htmlFor="endDate" className="date-label mb-0 me-2">End Date</label>
                <DatePicker
                  id="endDate"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="form-control"
                  placeholderText="Select a date"
                />
              </div>
            </div>
            <div className="col-auto d-flex">
              <Button type="button">Filter</Button>
              <Button type="button" className="ms-3" onClick={handleDownload}>Download</Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              {reportsData && (
                <Table
                  data={reportsData}
                  columns={columns}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalPages={totalPages}
                />
              )}
            </div>
          </div>
        </div>
        <ReportDeleteModal reportId={selectedReportId} setReportId={setSelectedReportId} />
        <ManageReportForm defaultData={defaultData} setDefaultData={setDefaultData} />
      </div>
    </DashboardHOC>
  );
};

export default Report;
