import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
  data: null,
  totalCount: 0,
  storeList: [],
};

export const AddStore = createAsyncThunk(
  'admin/AddStore',
  async (data) => {
    try {
      const response = await myAxios.post(`admin/store`, data?.formData);
      return { success: true, message: "store added successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  }
);

export const FetchStores = createAsyncThunk(
  'admin/FetchStores',
  async (data) => {
    try {
      const response = await myAxios.get(`admin/store`, {
        params: {
          ...data?.query
        }
      });
      return { success: true, message: "Stores fetched successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  }
);

export const UpdateStore = createAsyncThunk(
  'admin/UpdateStore',
  async (data) => {
    try {
      const response = await myAxios.put(`admin/store/${data?.query?.storeId}`,
        data?.formData
      );
      return { success: true, message: "store updated successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  }
);

export const DeleteStore = createAsyncThunk(
  'admin/DeleteStore',
  async (data) => {
    try {
      const response = await myAxios.delete(`admin/store/${data?.query?.storeId}`);
      return { success: true, message: "store deleted successfully!", data: response?.data };
    } catch (error) {
      if (error?.response?.data) {
        return { success: false, message: error?.response?.data?.message };
      } else {
        return { success: false, message: 'Sorry, something went wrong!' };
      }
    }
  }
);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    clearStoreData: state => {
      state.data = null;
    },
    setStoreData: (state, action) => {
      state.data = action.payload?.data?.stores;
      state.totalCount = action.payload?.data?.totalCount;
    },
    addStoreData: (state, action) => {
      const newData = action.payload?.data;
      state.data.push(newData);
    },
    updateStoreData: (state, action) => {
      const newData = action.payload?.data;
      const updatedData = state.data.map((obj) => {
        if (obj._id === newData._id) {
          return newData;
        } else {
          return obj;
        }
      });
      state.data = updatedData;
    },
    deleteStoreData: (state, action) => {
      const deletedId = action.payload?.data?._id;
      state.data = state.data.filter(obj => obj._id !== deletedId);
    },
    setStoreList: (state, action) => { // Reducer to set store list
      state.storeList = action.payload;
    },
  },
});

export const storeReducer = storeSlice.reducer;
export const { clearStoreData, setStoreData, addStoreData, updateStoreData, deleteStoreData , setStoreList } = storeSlice.actions;
export const selectStoreData = (state) => state.store.data;
export const selectStoreCount = (state) => state.store.totalCount;
export const selectStoreList = (state) => state.store.storeList; // Selector for store list