export const CALL_STATE = {
    OFFLINE: "off-line",
    CONNECTING: "connecting",
    READY: "ready",
    ON_CALL: "on-call",

    INCOMING: "incoming",
    ACCEPTED: "accepted",
    REJECTED: "rejected",

    RINGING: "ringing",
    BUSY: "busy",
    FAILED: "failed",
    CONNECTED: "connected",
    DISCONNECTED: "disconnected",
    ERROR: "error",
};
