import React from 'react';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import Pagination from '../common/Pagination';

export default function Table({
    data,
    columns,
    currentPage,
    totalPages,
    handlePageChange
}) {

    const [sorting, setSorting] = React.useState([]);

    const table = useReactTable({
        columns,
        data,
        debugTable: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
    });

    const rows = table?.getRowModel()?.rows ?? [];


    return (
        <>
            <table className="table">
                <thead>
                    {table?.getHeaderGroups()?.map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return <th key={header.id} colSpan={header.colSpan} >
                                    {
                                        header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : ''
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                                ? 'Sort descending'
                                                                : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: <FaChevronUp className='ms-2' />,
                                                    desc: <FaChevronDown className='ms-2' />,
                                                }[header.column.getIsSorted()] ?? null}
                                            </div>
                                        )
                                    }
                                </th>
                            }
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {rows?.map((row, index) => (
                        <tr key={index}>
                            {row?.getVisibleCells()?.map((cell, idx) => {
                                // cell?.column?.id === 'id' && (cell?.value = <Link to={`/users/${cell?.value}`}>{cell?.value}</Link>); // TODO: fix this
                                return (
                                    <td key={idx}>{flexRender(cell.column.columnDef.cell, cell?.getContext())}</td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table >
            <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
        </>
    );
}

