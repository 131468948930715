import React from 'react'
import { Controller } from 'react-hook-form'

const Checkbox = ({
    name,
    control,
    rules,
    defaultValue,
    label,
    className = "",
}) => {

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className={`${className}`}>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className={`form-check-input ${error ? 'border-danger' : 'border-primary'}`}
                            id={name}
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                        />
                        {label && (
                            <label
                                className='form-check-label text-grey'
                                htmlFor={name}
                            >
                                {label}
                            </label>
                        )}
                    </div>
                </div>
            )}
        />
    )
}

export default Checkbox
