import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
    totalCount: 0,
};

export const AddGreeting = createAsyncThunk(
    'admin/AddGreeting',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/greeting`, data?.formData);
            return { success: true, message: "greeting added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchGreetings = createAsyncThunk(
    'admin/FetchGreetings',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/greeting`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "greeting fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const UpdateGreeting = createAsyncThunk(
    'admin/UpdateGreeting',
    async (data) => {
        try {
            const response = await myAxios.put(`admin/greeting/${data?.query?.greetingId}`,
                data?.formData
            );
            return { success: true, message: "greeting updated successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const greetingSlice = createSlice({
    name: 'greeting',
    initialState,
    reducers: {
        clearGreetingData: state => {
            state.data = null;
        },
        setGreetingData: (state, action) => {
            state.data = action.payload?.data?.users;
            state.totalCount = action.payload?.data?.totalCount;
        },
    },
});

export const greetingReducer = greetingSlice.reducer;
export const { clearGreetingData, setGreetingData } = greetingSlice.actions;

export const selectGreetingData = (state) => state.greeting.data;
export const selectGreetingCount = (state) => state.greeting.totalCount;
