import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

const Pagination = ({ 
    currentPage, 
    totalPages, 
    handlePageChange
 }) => {
    const handleClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            handlePageChange(page);
        }
    };

    return (
        <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <a className="page-link" href="javascript:void(0)" aria-disabled={currentPage === 1} onClick={() => handleClick(currentPage - 1)}>
                    <FaChevronLeft />
                </a>
            </li>
            {[...Array(totalPages)]?.map((_, index) => (
                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <a className="page-link" href="javascript:void(0)" onClick={() => handleClick(index + 1)}>
                        {index + 1}
                    </a>
                </li>
            ))}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a className="page-link" href="javascript:void(0)" aria-disabled={currentPage === totalPages} onClick={() => handleClick(currentPage + 1)}>
                    <FaChevronRight />
                </a>
            </li>
        </ul>
    );
};

export default Pagination;


{/* <ul className="pagination justify-content-center">
<li className="page-item disabled">
    <a className="page-link" href="javascript:void(0)" aria-disabled="true">
        <FaChevronLeft />
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        1
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        2
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        ..
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        9
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        10
    </a>
</li>
<li className="page-item">
    <a className="page-link" href="javascript:void(0)">
        <FaChevronRight />
    </a>
</li>
</ul> */}