import React from 'react'
import { FaMicrophone, FaMicrophoneSlash, FaPause, FaPhoneSlash } from 'react-icons/fa'

const OnCall = ({
    handleHangup,
    handleMuteToggle,
    isMuted,
    handleHoldToggle,
    isOnHold,
}) => {
    const handleHangupClick = () => {
        handleHangup();
    };
    return (
        <ul>
            <li>
                <a href="javascript:void(0)" className="bg-primary" onClick={handleMuteToggle}>
                    {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                </a>
                <span>{isMuted ? "Unmute" : "Mute"}</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-danger" onClick={handleHangupClick}>
                    <FaPhoneSlash />
                </a>
                <span>End</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-cyan" onClick={handleHoldToggle}>
                    <FaPause />
                </a>
                <span>{isOnHold ? "Resume" : "Hold"}</span>
            </li>
            {/* <li>
                <a href="javascript:void(0)" className="bg-orange">
                    <FaPhone />
                </a>
                <span>Dialpad</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-green">
                    <FaPlus />
                </a>
                <span>Add</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-secondary">
                    <FaSquare />
                </a>
                <span>Record</span>
            </li> */}
        </ul>
    )
}

export default OnCall