import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { NAVIGATION } from './Routes'

const PrivateRoutes = () => {
    const authToken = localStorage.getItem("authToken");

    return (
        ((!authToken || authToken === null || authToken === undefined)) ? <Navigate to={NAVIGATION.LOGIN} /> : <Outlet />
    )
}

export default PrivateRoutes;