import React from 'react'
import Input from '../ui/Input'
import useApiRequest from '../../store/slices/hook/useApiRequest';
import { MdSearch } from 'react-icons/md';

const TableSearch = ({
    apiAction,
    setValueAction,
}) => {
    const beforeAPICall = (formatDate) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    ...formatDate,
                },
            },

        }
    };

    const { handleSubmit, control } = useApiRequest({
        apiAction,
        setValueAction,
        successMessage: false,
        beforeAPICall
    });

    return (
        <div className="col-md-6 col-lg-3">
            <form className="position-relative" onSubmit={handleSubmit}>
                <Input search={true} control={control} name="search" placeholder="Search" />
                <button type='submit' className="border-0 outline-0 bg-transparent position-absolute top-50  ms-5 translate-middle-y end-0 search fs-5 d-flex justify-content-center align-items-center h-100">
                    <MdSearch />
                </button>
            </form>
        </div>

    )
}

export default TableSearch