import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { NAVIGATION } from '../router/Routes'
import { useDispatch, useSelector } from 'react-redux';
import { selectLoggedInRole } from '../../store/slices/api/auth/loginSlice';
import { clearSelectedStore, setLogoutModal } from '../../store/slices/misc/commonStatesSlices';

const menuItems = [
    {
        to: NAVIGATION.INNER_NAVIGATION.DASHBOARD,
        icon: '/images/dash-icon.svg',
        label: 'Dashboard',
    },
    {
        to: NAVIGATION.INNER_NAVIGATION.MANAGE_STORE,
        icon: '/images/store-icon.svg',
        label: 'Manage Stores',
    },
    {
        to: NAVIGATION.INNER_NAVIGATION.MANAGE_USER,
        icon: '/images/user-icon.svg',
        label: 'Manage Users',
    },
    {
        to: NAVIGATION.INNER_NAVIGATION.CONVERSATIONS,
        icon: '/images/conver-icon.svg',
        label: 'Conversations',
    },
    {
        to: NAVIGATION.INNER_NAVIGATION.REPORT,
        icon: '/images/report-icon.svg',
        label: 'Reports',
    },
    {
        to: NAVIGATION.INNER_NAVIGATION.CUSTOMERS,
        icon: '/images/customer-icon.svg',
        label: 'Customers',
    },
];

const Sidebar = () => {
    const { pathname } = useLocation();
    const loggedInUserRole = useSelector(selectLoggedInRole)
    const dispatch = useDispatch();
    
    const handleLogoutModalToggle = (value) => {
        dispatch(setLogoutModal(value))
    };
    return (
        <aside className="sidebar">
            <div className="row g-0">
                <div className="col-12">
                    <div className="logo-top">
                        <div className="logo">
                            <img src="/images/logo.svg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0 h-100">
                <div className="col-12">
                    <div className="menu">
                        <ul>
                            {menuItems.map((item, index) => {
                                if ((item.to === NAVIGATION.INNER_NAVIGATION.MANAGE_STORE || item.to === NAVIGATION.INNER_NAVIGATION.MANAGE_USER) && loggedInUserRole !== 'admin') {
                                    return null;
                                }
                                return (
                                    <li key={index}>
                                        <Link to={item.to} className={pathname === item.to ? 'active' : ''} onClick={() => dispatch(clearSelectedStore())}>
                                            <img src={item.icon} alt="" />
                                            <span>{item.label}</span>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                        <ul className="mt-auto">
                            <li>
                                <a href="javascript:void(0)" onClick={() => handleLogoutModalToggle(true)}>
                                    <img src="/images/logout.svg" alt="" />
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    )   
}

export default Sidebar