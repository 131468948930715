import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
};

export const HoldCall = createAsyncThunk(
    'admin/HoldCall',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/holdCall?callSid=${data?.query?.CallSid}`);
            return { success: true, message: "hold button triggered!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const holdCallSlice = createSlice({
    name: 'holdCall',
    initialState,
    reducers: {
        clearHoldCallData: state => {
            state.data = null;
        },
        setHoldCallData: (state, action) => {
            state.data = action.payload?.token;
        },
    },
});

export const holdCallReducer = holdCallSlice.reducer;
export const { clearHoldCallData, setHoldCallData } = holdCallSlice.actions;

export const selectHoldCallData = (state) => state.holdCall.data;

