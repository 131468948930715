import React, { useEffect, useRef, useState } from 'react';
import { FaPhone } from 'react-icons/fa';
import { FaPencil } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { AddRecordingFile, clearConversationData, FetchChat, handleAddRecordingFile, selectConversationData, setConversationData } from '../../../store/slices/api/inner/conversationSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import getTimeInAMPMFormat from '../../../utils/fromatTime';
import ChatForm from './ChatForm';
import { selectSelectedContact } from '../../../store/slices/api/inner/conversationSlice';
import { AudioPlayer } from 'react-audio-player-component';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import { setSelectedMobileNumber } from '../../../store/slices/misc/callSlice';
import { setEditNameModal } from '../../../store/slices/misc/commonStatesSlices';
import { BsFillTelephoneInboundFill, BsTelephoneOutboundFill } from 'react-icons/bs';

const renderMessage = (message, setCallSid) => {

    switch (message?.type) {
        case 'message':
            return (
                <div className={message?.isSender ? 'left-chat' : 'right-chat'} key={message?._id}>
                    <div className={`name d-flex align-items-center gap-2 mb-2 ${message?.isSender ? "flex-row" : "flex-row-reverse"}`}>
                        <div className="img">
                            <img src="/images/user-img.png" alt="" />
                        </div>
                        <span>{message?.fromUser?.userName || "GS Admin"}</span>
                    </div>
                    <div className={`message ${!message?.isSender && "ms-auto"}`}>
                        <span>{message?.text || " "}</span>
                        <small className="text-grey">{getTimeInAMPMFormat(message?.dateTime)}</small>
                    </div>
                </div>
            );
        case 'divider':
            return (
                <div className="date" key={message?._id}>
                    <span>{message?.date}</span>
                </div>
            );
        case 'recording':
            return (
                <div className={message?.isSender ? 'left-chat' : 'right-chat'} key={message?._id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="name d-flex align-items-center gap-2 mb-2">
                            <div className="img">
                                <img src="/images/user-img.png" alt="" />
                            </div>
                            <span>{message?.sender}</span>
                        </div>
                        <div className="name d-flex align-items-center gap-2 mb-2 flex-row-reverse">
                            <div className="img">
                                <img src="/images/user-img.png" alt="" />
                            </div>
                            <span>{message?.receiver}</span>
                        </div>
                    </div>
                    <div className="recording text-grey d-flex align-items-center flex-wrap">
                        <div className="d-flex align-items-center gap-2">{message?.duration} <img src="/images/recording.svg" alt="" /> {message?.time}</div>
                        <div className="action d-flex align-items-center">
                            <a href="javascript:void(0)"><img src="/images/icon1.svg" alt="" className="me-2" />Transcript</a>
                            <a href="javascript:void(0)"><img src="/images/icon2.svg" alt="" className="me-2" />Download</a>
                        </div>
                    </div>
                </div>
            );
        case 'note':
            return (
                <div className={'left-chat'} key={message?._id}>
                    <div className="name d-flex align-items-center gap-2 mb-2 flex-row-reverse">
                        <div className="img">
                            <img src="/images/user-img.png" alt="" />
                        </div>
                        <span>{message?.fromUser?.userName}</span>
                    </div>
                    <div className="note" style={{ marginLeft: "70%" }}>
                        <div className="note-date text-grey d-flex justify-content-between mb-2">
                            Note: {getTimeInAMPMFormat(message?.dateTime)} <span><a href="javascript:void(0)" className="text-success"><FaPencil /></a></span>
                        </div>
                        <p>{message?.text}</p>
                    </div>
                </div>
            );
        case 'calling':
            return (
                <div key={message?._id}>
                    {
                        message.callstatus === "Completed" ?
                            (
                                <div className='ml-0 p-2v left-0'>
                                    <div className={`name d-flex align-items-center gap-2 mb-2 ${message?.isSender ? "flex-row-reverse" : "flex-row"}`}>
                                        <div className="img">
                                            <img src="/images/user-img.png" alt="" />
                                        </div>
                                        <span>{message.isSender ? message?.fromUser?.userName : message?.toCustomer?.phone}</span>
                                    </div>
                                    <div className={`message ${message?.isSender && "ms-auto"}`}>
                                        {message?.recording && <AudioPlayer
                                            src={message?.recording}
                                            minimal={true}
                                            width={350}
                                            trackHeight={40}
                                            barWidth={3}
                                            gap={1}
                                            visualise={true}
                                            backgroundColor="#fff"
                                            barColor="#959595"
                                            barPlayedColor="#5C61F2"
                                            skipDuration={2}
                                            showLoopOption={true}
                                            showVolumeControl={true}
                                            hideSeekBar={true}
                                            hideSeekKnobWhenPlaying={true}
                                        />}
                                        {message.recording ? (
                                            <a href={message.recording} target='_blank' download={`${message?._id}-recording.mp3`}>Download Audio</a>
                                        ) : (
                                            <a href="javascript:void(0)" onClick={() => setCallSid(message?.CallSid)}>Download Audio</a>
                                        )}
                                        <small className="text-grey">{getTimeInAMPMFormat(message?.dateTime)}</small>
                                    </div>
                                </div>

                            )
                            :
                            (
                                <div className={message?.isSender ? 'missed-call bg-light-grey p-3 mt-4' : 'missed-call bg-light-grey p-3 mt-4 right-chat'} key={message?.key}>
                                    <div className="d-flex gap-2 align-items-center text-red">{message?.isSender ? <><BsTelephoneOutboundFill /><span>No Answer</span></> : <><BsFillTelephoneInboundFill /><span>Missed Call</span></>}</div>
                                    <div className="d-block"><small className="text-grey">{message?.fromUser?.userName} | {getTimeInAMPMFormat(message?.dateTime)}</small></div>
                                </div>
                            )
                    }
                </div>
            );

        default:
            return null;
    }
};


const ChatSection = () => {
    const chatData = useSelector(selectConversationData);
    const selectedContact = useSelector(selectSelectedContact);
    const chatContainerRef = useRef(null);
    const dispatch = useDispatch();
    const [callSid, setCallSid] = useState(null);
    const loggedInData = useSelector(selectLoggedInData)

    const handleModalToggle = (value) => {
        dispatch(setEditNameModal(value))
    };

    const beforeAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    contactId: selectedContact?._id,
                },
            },
        };
    };

    const { handleSubmit: fetchChatData } = useApiRequest({
        apiAction: FetchChat,
        setValueAction: setConversationData,
        successMessage: false,
        beforeAPICall,
    });


    useEffect(() => {
        if (selectedContact?._id) {
            dispatch(setSelectedMobileNumber(selectedContact?.customerId?.phone || ""))
            fetchChatData();
        } else {
            dispatch(clearConversationData())
        }
    }, [selectedContact]);

    // Scroll to the bottom when chatData changes or initially on component mount
    useEffect(() => {
        scrollToBottom();
    }, [chatData]);

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const beforeAddRecordingAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    callSid,
                },
            },
        };
    };

    const { handleSubmit: addRecordingURL } = useApiRequest({
        apiAction: AddRecordingFile,
        setValueAction: handleAddRecordingFile,
        successMessage: false,
        beforeAPICall: beforeAddRecordingAPICall,
    });

    useEffect(() => {
        if (callSid) {
            addRecordingURL();
        }
    }, [callSid]);

    return (
        <div className="col-lg-9 chat-container">
            {/* sticky-top */}
            {/* ${loggedInData?.role === "admin" && "py-3"} */}
            <div className={`chat-head d-flex justify-content-between bg-white ${loggedInData?.role === "admin" && "py-3"}`}>
                <div className="top-name d-flex align-items-center position-relative">{selectedContact?.customerName ? selectedContact?.customerName : selectedContact?.customerId?.phone}<span>
                    <a href="javascript:void(0)" onClick={() => handleModalToggle(true)} className="text-white">
                        <FaPencil />
                    </a></span>
                </div>
                {loggedInData?.role !== "admin" && <div className="top-call">
                    <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#dialerModal" className="text-white">
                        <FaPhone />
                    </a>
                </div>}
            </div>
            <div className="chat-conversation" style={{ maxHeight: 'calc(100vh - 356px)', overflowY: 'auto' }} ref={chatContainerRef}>
                {chatData && chatData?.map((message) => renderMessage(message, setCallSid))}
            </div>

            <ChatForm />
        </div>
    );
};

export default ChatSection;
