export default function getTimeInAMPMFormat(dateTimeString) {
    const date = new Date(dateTimeString);
    const timeFormat = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    });
    const [{ value: hour }, , { value: minute }, { value: period }] = timeFormat.formatToParts(date);

    return `${hour}:${minute} ${period}`;
}