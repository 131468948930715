import React from 'react'
import { Controller } from 'react-hook-form'

const Select = ({
    name,
    control,
    rules,
    defaultValue,
    label,
    className = "",
    options = [],
}) => {

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <div className={`${className}`}>

                    {label && <label>{label}</label>}
                    <select {...field} className={`form-select form-control ${error && 'text-danger border-danger'}`}>
                        {
                            options.map(option => (
                                <option key={option?.label} defaultValue={option?.selected} value={option?.value}>{option?.label}</option>
                            ))
                        }
                    </select>

                    {error && (
                        <p className="text-danger">
                            {error.message || 'Error'}
                        </p>
                    )}
                </div>
            )
            }
        />
    )
}

export default Select