import React from 'react'
import DialPad from './DialPad';
import OnCall from './OnCall';
import { CALL_STATE } from '../../../utils/callStates';
import Timer from './Timer';

const DialerModal = ({
    handleCall,
    phoneNumber,
    setPhoneNumber,
    handleHangup,
    callState,
    handleMuteToggle,
    isMuted,
    handleHoldToggle,
    isOnHold,
}) => {
    const handleCallClick = () => {
        (handleCall && phoneNumber) ? handleCall(phoneNumber) : console.log("Call not available");
    };

    return (
        <div className="modal fade top-call" id="dialerModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        {/* <h5 className="modal-title text-center mb-3">0468 782 387</h5> */}

                        <input
                            className="dialer-input mb-3"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                        />

                        <div className="text-center">
                            <figure className="mb-2">
                                <img src="/images/user-img-big.png" alt="" />
                            </figure>
                            <h6>
                                {callState === CALL_STATE.RINGING ? "Ringing" : callState === CALL_STATE.ACCEPTED ? <Timer/> : ""}
                            </h6>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="calls-option">
                            {callState === CALL_STATE.READY && <DialPad handleCallClick={handleCallClick} setPhoneNumber={setPhoneNumber} />}
                            {callState === CALL_STATE.ACCEPTED && <OnCall
                                handleHangup={handleHangup}
                                handleMuteToggle={handleMuteToggle}
                                isMuted={isMuted}
                                handleHoldToggle={handleHoldToggle}
                                isOnHold={isOnHold}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DialerModal