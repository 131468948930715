import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import Input from '../../ui/Input';
import Select from '../../ui/Select';
import Button from '../../ui/Button';
import { AddCustomer, UpdateCustomer, addCustomerData, updateCustomerData } from '../../../store/slices/api/inner/customerSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useDispatch, useSelector } from 'react-redux';
import { FetchUsers, selectUserData, setUserData } from '../../../store/slices/api/inner/userSlice';
import { setManageCustomerFormModal } from '../../../store/slices/misc/commonStatesSlices';

const ManageCustomerForm = ({
  defaultData,
}) => {
  const usersData = useSelector(selectUserData)
  const [userOptions, setUserOptions] = useState([{ label: "Select", value: null, selected: true }]);
  const dispatch = useDispatch();
  const modalState = useSelector(state => state.common.manageCustomerFormModal)

  const handleModalToggle = (value) => {
    dispatch(setManageCustomerFormModal(value))
  };

  const defaultValues = {
    firstName: defaultData?.firstName || "",
    lastName: defaultData?.lastName || "",
    email: defaultData?.email || "",
    phone: defaultData?.phone || "",
    assignedUserId: defaultData?.assignedUserId || "",
  };

  const beforeUpdateAPICall = (formData) => {
    if (defaultData?._id) {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
          query: {
            customerId: defaultData?._id,
          },
        },
      };
    } else {
      return {
        success: true,
        refactoredData: {
          formData: {
            ...formData,
          },
        },
      };
    }
  };

  const afterAPICall = (response) => {
    handleModalToggle(false)
  }

  const { isLoading, control, setValue, handleSubmit, reset } = useApiRequest({
    apiAction: defaultData?._id ? UpdateCustomer : AddCustomer,
    setValueAction: defaultData?._id ? updateCustomerData : addCustomerData,
    defaultValues,
    clearForm: defaultData?._id ? false : true,
    beforeAPICall: beforeUpdateAPICall,
    afterAPICall,
  });

  useEffect(() => {
    if (defaultData) {
      setValue("firstName", defaultData?.firstName);
      setValue("lastName", defaultData?.lastName);
      setValue("email", defaultData?.email);
      setValue("phone", defaultData?.phone);
      setValue("assignedUserId", defaultData?.assignedUserId);

    } else {
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("assignedUserId", "");

    }
  }, [defaultData]);


  // fetching user data
  const { handleSubmit: fetchUserData } = useApiRequest({
    apiAction: FetchUsers,
    setValueAction: setUserData,
    successMessage: false,
    isForm: false,
  });

  useEffect(() => {
    if (!usersData) {
      fetchUserData();
    }

    setUserOptions(() => usersData ? [{ label: "Select", value: null, selected: true }, ...usersData?.map((item, idx) => {
      return {
        value: item._id,
        label: item.userName,
        selected: false,
      }
    })]
      : [{ label: "Select", value: null, selected: true }])
  }, [usersData])


  return (
    modalState ? <>
      <div
        className="modal fade show"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{defaultData ? "Edit" : "Add New"} Customer</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleModalToggle(false)}
                aria-label="Close"
              >
                <FaTimes />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <Input
                  label="First Name"
                  control={control}
                  name="firstName"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
                <Input
                  label="Last Name"
                  control={control}
                  name="lastName"
                  className="mb-3"
                />
                <Input
                  label="Email"
                  control={control}
                  name="email"
                  className="mb-3"
                />
                <Input
                  label="Phone Number"
                  control={control}
                  name="phone"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                />
                <Select
                  label="User"
                  control={control}
                  name="assignedUserId"
                  className="mb-3"
                  rules={{
                    required: "this filed is required"
                  }}
                  options={userOptions}
                />
              </div>
              <div className="modal-footer">
                <Button type='button' variant='secondary' className="btn-w" onClick={() => handleModalToggle(false)}>Close</Button>
                <Button isLoading={isLoading} className="btn-w">{defaultData ? "Save Changes" : "Add"}</Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
    </> : null
  )
}

export default ManageCustomerForm