import React, { useState } from 'react'
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useSelector } from 'react-redux';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import { AddChat, addChatData, selectSelectedContact } from '../../../store/slices/api/inner/conversationSlice';
import TextArea from '../../ui/TextArea';
import { FaFileLines } from 'react-icons/fa6';


const NoteForm = () => {
    // button click
    const selectedContact = useSelector(selectSelectedContact);
    const loggedInData = useSelector(selectLoggedInData);
    const [isFormVisible, setFormVisible] = useState(false);
    
    const beforeSubmitAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                formData: {
                    ...formData,
                    contactId: selectedContact?._id,
                    customerId: typeof (selectedContact?.customerId) === "string" ? selectedContact?.customerId : selectedContact?.customerId?._id,
                    // userId: loggedInData?._id,
                    userId: loggedInData?.role === "admin" ? "66794eea8cef50fc5cd5cbd6" : loggedInData?._id,
                },
            },
        };
    };

    const afterAPICall = () => {
        reset();
        setValue('text', "");
        setFormVisible(false);
    }

    const toggleFormVisibility = () => {
        setFormVisible(!isFormVisible);
    };

    const { control, reset, setValue, handleSubmit } = useApiRequest({
        apiAction: AddChat,
        setValueAction: addChatData,
        reset: true,
        successMessage: false,
        afterAPICall,
        beforeAPICall: beforeSubmitAPICall,
    });


    return (
        <div className="add-note">
            <label className="btn btn-secondary" htmlFor="item-3" onClick={() => toggleFormVisibility()}>
                <FaFileLines className='me-1' /> Add Note </label>
            <input type="checkbox" name="one" id="item-3" className="hide-input" />
            {isFormVisible && (
                <div className="form-mod">
                    <form onSubmit={handleSubmit}>
                        <Input
                            name="type"
                            type="hidden"
                            defaultValue="note"
                            control={control}
                        />
                        <TextArea
                            name="text"
                            control={control}
                            placeholder="Add a note..."
                            rules={{
                                required: "this filed is required"
                            }}
                        />
                        <Button style={{position:"absolute" , left:"90%" , bottom:"7%"}}  >Send</Button>
                        <span className="arrow-down"></span>
                    </form>
                </div>)}
        </div>

    )
}

export default NoteForm