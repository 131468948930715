import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NAVIGATION } from '../../components/router/Routes';
import Input from '../../components/ui/Input';
import Checkbox from '../../components/ui/Checkbox';
import useApiRequest from '../../store/slices/hook/useApiRequest';
import { login, setLoginData } from '../../store/slices/api/auth/loginSlice';
import Button from '../../components/ui/Button';

const LoginPage = () => {
  const navigate = useNavigate();

  const afterAPICall = (response) => {
    navigate(NAVIGATION.INNER_NAVIGATION.DASHBOARD);
  };

  const { isLoading, control, handleSubmit } = useApiRequest({
    apiAction: login,
    setValueAction: setLoginData,
    afterAPICall
  });

  return (
    <section className="login-main" style={{ backgroundImage: "url('/images/login-bg.jpg')" }}>
      <div className="login">
        <div className="content text-center">
          <div className="logo mb-4">
            <img src="/images/logo.svg" alt="" />
          </div>
          <h1 className="mb-2">
            <span>Login to GS Phone</span>
          </h1>
          <p className="mb-4 text-grey">
            Enter your personal details to create account
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <Input
            label="Email"
            control={control}
            name="email"
            className="mb-3"
            rules={{ required: "This field is required" }}
          />
          <Input
            label="Password"
            control={control}
            name="password"
            type="password"
            className="mb-3"
            rules={{ required: "This field is required" }}
          />

          <div className="form-group mb-3">
            <Button type='submit' isLoading={isLoading} className="w-100">Sign In</Button>
          </div>
        </form>
        <div className="form-group d-flex justify-content-between">
          <Checkbox
            label="Remember Password"
            control={control}
            name="remember"
          />
          <span>
            <Link to={NAVIGATION.AUTH_NAVIGATION.FORGET_PASSWORD}>Forgot Password?</Link>
          </span>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
