import React from "react";
import { FaLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { NAVIGATION } from "../../components/router/Routes";
import Input from "../../components/ui/Input";
import { useForm } from "react-hook-form";
import Button from "../../components/ui/Button";

const ForgetPassword = () => {
  const { control, handleSubmit } = useForm();
  
  const onSubmit = async (data) => {
    console.log('form data: ', data);
}
  return (
    <section className="login-main" style={{
      backgroundImage: "url('/images/login-bg.jpg')"
    }}>
      <div className="login">
        <div className="content text-center">
          <div className="logo mb-4">
            <img src="/images/logo.svg" alt="" />
          </div>
          <h1 className="mb-2">
            <span>Forgot Password</span>
          </h1>
          <p className="mb-4 text-grey">
            Please enter the email address you'd like your password reset
            information sent to
          </p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Enter Email Address"
            control={control}
            name="email"
            className="mb-3"
            rules={{
              required: "this filed is required"
            }}
          />

          <div className="form-group mb-3">
            <Button className="w-100">Send</Button>
          </div>

        </form>
        <div className="form-group text-center">
          <Link to={NAVIGATION.LOGIN}>
            <FaLeftLong /> Back to login
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
