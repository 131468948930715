import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
};

export const FetchDashboardTotalStats = createAsyncThunk(
    'admin/FetchDashboardTotalStats',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/dashboard`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "dashboard fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDashboardData: state => {
            state.data = null;
        },
        setDashboardData: (state, action) => {
            state.data = action.payload?.data;
        },
    },
});

export const dashboardReducer = dashboardSlice.reducer;
export const { clearDashboardData, setDashboardData } = dashboardSlice.actions;

export const selectDashboardData = (state) => state.dashboard.data;
