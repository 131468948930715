import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import myAxios from '../../../../lib/axios';

const initialState = {
    data: null,
    totalCount: 0,
};

export const AddCustomer = createAsyncThunk(
    'admin/AddCustomer',
    async (data) => {
        try {
            const response = await myAxios.post(`admin/customer`, data?.formData);
            return { success: true, message: "customer added successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const FetchCustomers = createAsyncThunk(
    'admin/FetchCustomers',
    async (data) => {
        try {
            const response = await myAxios.get(`admin/customer`, {
                params: {
                    ...data?.query
                }
            });
            return { success: true, message: "customer fetched successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const UpdateCustomer = createAsyncThunk(
    'admin/UpdateCustomer',
    async (data) => {
        try {
            const response = await myAxios.put(`admin/customer/${data?.query?.customerId}`,
                data?.formData
            );
            return { success: true, message: "customer updated successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

export const DeleteCustomer = createAsyncThunk(
    'admin/DeleteCustomer',
    async (data) => {
        try {
            const response = await myAxios.delete(`admin/customer/${data?.query?.customerId}`);
            return { success: true, message: "customer deleted successfully!", data: response?.data };
        } catch (error) {
            if (error?.response?.data) {
                return { success: false, message: error?.response?.data?.message };
            } else {
                return { success: false, message: 'Sorry, something went wrong!' };
            }
        }
    },
);

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        clearCustomerData: state => {
            state.data = null;
        },
        setCustomerData: (state, action) => {
            state.data = action.payload?.data?.users;
            state.totalCount = action.payload?.data?.totalCount;
        },
        addCustomerData: (state, action) => {
            const newData = action.payload?.data;
            state.data = state?.data ? [...state.data, newData] : [newData];
        },
        updateCustomerData: (state, action) => {
            const newData = action.payload?.data;

            const updatedData = state?.data?.map((obj) => {
                if (obj._id === newData._id) {
                    return newData;
                } else {
                    return obj;
                }
            });
            state.data = updatedData;
        },
        deleteCustomerData: (state, action) => {
            const deletedId = action.payload?.data?._id;
            state.data = state.data.filter(obj => obj._id !== deletedId);
        },
    },
});

export const customerReducer = customerSlice.reducer;
export const { clearCustomerData, setCustomerData, addCustomerData, updateCustomerData, deleteCustomerData } = customerSlice.actions;

export const selectCustomerData = (state) => state.customer.data;
export const selectCustomerCount = (state) => state.customer.totalCount;
