import React, { useState } from 'react';
import { FaFaceSmile } from 'react-icons/fa6';
import Button from '../../ui/Button';
import Input from '../../ui/Input';
import { FaChevronRight } from 'react-icons/fa';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useSelector } from 'react-redux';
import { AddChat, addChatData, selectSelectedContact } from '../../../store/slices/api/inner/conversationSlice';
import NoteForm from './NoteForm';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import EmojiPicker from 'emoji-picker-react';

const ChatForm = () => {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const selectedContact = useSelector(selectSelectedContact);
    const loggedInData = useSelector(selectLoggedInData);

    const beforeSubmitAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                formData: {
                    ...formData,
                    contactId: selectedContact?._id,
                    customerId: typeof (selectedContact?.customerId) === "string" ? selectedContact?.customerId : selectedContact?.customerId?._id,
                    userId: loggedInData?.role === "admin" ? "66794eea8cef50fc5cd5cbd6" : loggedInData?._id,
                },
            },
        };
    };

    const afterAPICall = () => {
        reset();
        setValue('text', "");
    };

    const { control, reset, setValue, handleSubmit } = useApiRequest({
        apiAction: AddChat,
        setValueAction: addChatData,
        reset: true,
        successMessage: false,
        afterAPICall,
        beforeAPICall: beforeSubmitAPICall,
    });

   

    const handleEmojiPickerToggle = () => {
        setShowEmojiPicker((prev) => !prev);
    };

    return (
        <div className='write-message'>
            <NoteForm />
            <form onSubmit={handleSubmit}>
                <div className="chat-message">
                    {showEmojiPicker && (
                        <EmojiPicker
                            className='position-absolute left-0'
                            style={{ bottom: '60%' }}
                            onEmojiClick={(emojiObject) => {
                                setValue("text", (prev) => prev + emojiObject.emoji);
                                setShowEmojiPicker(false);
                            }}
                        />
                    )}
                    <span>
                        <Input
                            name="type"
                            type="hidden"
                            defaultValue="message"
                            control={control}
                        />
                        <Input
                            name="text"
                            icon={
                                <FaFaceSmile
                                    style={{
                                        position: 'absolute',
                                        top: '32%',
                                        left: '10px',
                                        fontSize: '20px',
                                        color: 'gray',
                                        cursor: 'pointer',
                                    }}
                                    // onClick={handleEmojiPickerToggle}
                                />
                            }
                            control={control}
                            rules={{
                                required: "this field is required",
                            }}
                            placeholder="Type a message"
                        />
                    </span>
                    <Button type='submit'>
                        <FaChevronRight />
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChatForm;
