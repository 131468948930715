import React from 'react'
import { FaTimes } from 'react-icons/fa';
import { FaHashtag, FaPhone, FaPlus } from 'react-icons/fa6';

const numberList = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: <FaPlus />, value: "+" },
    { label: 0, value: 0 },
    { label: <FaHashtag />, value: "#" },
]

const DialPad = ({
    setPhoneNumber,
    handleCallClick
}) => {
    return (
        <ul>
            {numberList.map((item) => (
                <li
                    key={item.value}
                >
                    <a href="javascript:void(0)" className="bg-secondary" onClick={() => {
                        setPhoneNumber((phoneNumber) => phoneNumber + item.value);
                    }}>
                        {item.label}
                    </a>
                </li>
            ))}
            <li>
                <button href="javascript:void(0)"
                    onClick={() => setPhoneNumber("")}
                    className="remove-all-icon bg-secondary">
                    <FaTimes />
                </button>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-green"
                    onClick={handleCallClick}
                >
                    <FaPhone />
                </a>
            </li>
            <li>
                <a href="javascript:void(0)"
                    onClick={() => setPhoneNumber((phoneNumber) =>
                        phoneNumber.substring(0, phoneNumber.length - 1)
                    )}
                    className="bg-secondary">
                    <FaTimes />
                </a>

            </li>

        </ul>
    )
}

export default DialPad