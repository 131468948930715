import React, { useEffect, useState } from 'react'
import { FaEllipsisVertical, FaGear, FaPenToSquare, FaRectangleList, FaTrashCan } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedContact, FetchContact, FetchStoreContact, RemoveContact, removeContactSuccess, selectContactData, setContactData, setSelectedContact } from '../../../store/slices/api/inner/conversationSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { setNewMessageModal } from '../../../store/slices/misc/commonStatesSlices';

const RecentChatsUserList = () => {

    const contactData = useSelector(selectContactData);
    const dispatch = useDispatch();
    const [contactId, setContactId] = useState(null);
    const selectedStore = useSelector(state => state.common.selectedStore)

    const handleModalToggle = (value) => {
        dispatch(setNewMessageModal(value))
    };

    const beforeFetchApiCall = () => {
        return {
            success: true,
            refactoredData: {
                query: {
                    storeId: selectedStore?._id || null,
                },
            },

        }
    };

    const { handleSubmit: fetchContactData } = useApiRequest({
        apiAction: selectedStore?._id ? FetchStoreContact : FetchContact,
        setValueAction: setContactData,
        successMessage: false,
        beforeAPICall: beforeFetchApiCall,
        isForm: false,
    });

    useEffect(() => {
        fetchContactData();
    }, [selectedStore]);

    useEffect(() => {
        contactData ? dispatch(setSelectedContact(contactData[0])) : dispatch(clearSelectedContact());
    }, [contactData]);


    const handleItemClick = (item) => {
        dispatch(setSelectedContact(item));
    };


    // removing contact
    const beforeAPICall = (formatDate) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    contactId,
                },
            },

        }
    };

    const { handleSubmit: RemoveContactData } = useApiRequest({
        apiAction: RemoveContact,
        setValueAction: removeContactSuccess,
        beforeAPICall,
        isForm: false,
    });


    useEffect(() => {
        if (contactId) {
            RemoveContactData()
        }
        contactData && dispatch(setSelectedContact(contactData[0]));
    }, [contactId]);

    return (
        <div className="col-lg-3">
            <div className="left-side">
                {/* className='sticky-top' */}
                <div>
                    <div className="contact-head d-flex justify-content-between">
                        <h6 className="text-primary">Contacts</h6>
                        <div className="d-flex align-items-center gap-3">
                            <a href="javascript:void(0)" onClick={() => handleModalToggle(true)}>
                                <FaPenToSquare />
                            </a>
                            <a href="javascript:void(0)">
                                <FaRectangleList />
                            </a>
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#settingsModal">
                                <FaGear />
                            </a>
                        </div>
                    </div>

                    <div className="search-head">
                        <input type="text" className="form-control" placeholder="Search" />
                    </div>

                    <div className="main-list" style={{ maxHeight: 'calc(100vh - 317px)', overflowY: 'auto' }}>
                        <ul>
                            {contactData && contactData?.map((item, index) => (
                                <li key={index} onClick={() => handleItemClick(item)}>
                                    <div className="con">
                                        <div className="img">
                                            <img src="/images/user-img.png" alt="" />
                                        </div>
                                        <div className="txt">
                                            <span>{item?.customerName ? item?.customerName : item?.customerId?.phone ? item?.customerId?.phone : item?.customerId?.firstName ? `${item?.customerId?.firstName} ${item?.customerId?.lastName}` : ""}</span>
                                            <small className="text-grey">{item.date}</small>
                                        </div>
                                    </div>
                                    <div className="option dropstart">
                                        <a href="javascript:void(0)" data-bs-toggle="dropdown">
                                            <FaEllipsisVertical />
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a className="dropdown-item" href="javascript:void(0)" onClick={() => setContactId(item._id)}>
                                                    <FaTrashCan className='me-1' /> Remove
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecentChatsUserList