import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

const TextArea = ({
    name,
    control,
    rules,
    defaultValue,
    className = "",
    placeholder = "",
}) => {

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <div className={`${className}`}>
                    <textarea {...field} className={`form-control ${error && 'text-danger border-danger'}`} style={{height:"250px"}} placeholder={placeholder}></textarea>
                    {error && (
                        <p className="text-danger">
                            {error.message || 'Error'}
                        </p>
                    )}
                </div>
            )
            }
        />
    )
}

export default TextArea
