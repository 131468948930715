import React from 'react'
import { FaEnvelope, FaPhone, FaPhoneSlash } from 'react-icons/fa6'

const OnIncoming = ({
    handleAccept,
    handleReject,
}) => {
    return (
        <ul>
            <li>
                <a href="javascript:void(0)" className="bg-primary">
                    <FaEnvelope />
                </a>
                <span>Message</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-green" onClick={handleAccept}>
                    <FaPhone />
                </a>
                <span>Accept</span>
            </li>
            <li>
                <a href="javascript:void(0)" className="bg-danger" onClick={handleReject}>
                    <FaPhoneSlash />
                </a>
                <span>Decline</span>
            </li>
        </ul>
    )
}

export default OnIncoming