import React, { useEffect, useState } from "react";
import { FaPencil, FaTrashCan } from "react-icons/fa6";
import DashboardHOC from "../../components/hoc/DashboardHOC";
import Table from "../../components/ui/Table";
import ManageUserForm from "../../components/inner/manage-user/ManageUserForm";
import Button from "../../components/ui/Button";
import { FetchStoreUsers, FetchUsers, selectUserCount, selectUserData, setUserData } from "../../store/slices/api/inner/userSlice";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { useDispatch, useSelector } from "react-redux";
import UserDeleteModal from "../../components/inner/manage-user/UserDeleteModal";
import TableSearch from "../../components/common/TableSearch";
import { setSelectedMobileNumber } from "../../store/slices/misc/callSlice";
import { setManageUserFormModal, setUserDeleteModal } from "../../store/slices/misc/commonStatesSlices";
import { selectLoggedInData } from "../../store/slices/api/auth/loginSlice";


const ManageUser = () => {

    const [defaultData, setDefaultData] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const usersData = useSelector(selectUserData)
    const dispatch = useDispatch();
    const loggedInData = useSelector(selectLoggedInData)
    const selectedStore = useSelector(state => state.common.selectedStore)

    const handleModalToggle = (value, data) => {
        setDefaultData(data);
        dispatch(setManageUserFormModal(value))
    };

    const handleDeleteModalToggle = (value, id) => {
        setSelectedUserId(id)
        dispatch(setUserDeleteModal(value))
    };

    const handleSetNumber = (number) => {
        dispatch(setSelectedMobileNumber(number));
    }

    // pagination code.
    const [currentPage, setCurrentPage] = useState(1);
    const totalCount = useSelector(selectUserCount);
    const limit = 10;
    const [totalPages, setTotalPages] = useState(totalCount !== 0 ? Math.ceil(totalCount / limit) : 1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'userName',
                header: "Name",
                cell: info => <>
                    <span className="me-1">
                        <img src="/images/user-img.png" />
                    </span>
                    {info.getValue()}
                </>,
            },
            {
                accessorFn: row => row.phone,
                id: 'phone',
                cell: info => loggedInData?.role !== 'admin' ? <a href="javascript:void(0)" onClick={() => handleSetNumber(info?.getValue())} data-bs-toggle="modal" data-bs-target="#dialerModal">{info.getValue()}</a> : <a href="javascript:void(0)" style={{ cursor: "not-allowed" }}>{info.getValue()}</a>,
                header: () => <span>Phone Number</span>,
            },
            {
                accessorFn: (row) => row?.userstore?.userName || null,
                id: "store",
                header: "Store",
                cell: (info) => info.getValue() || "",
            },
            {
                accessorKey: 'email',
                header: () => 'Email',
            },
            {
                id: "actions",
                header: <span className='text-end'>
                    Actions
                </span>,
                cell: (info) => {
                    return <>
                        <a href="javascript:void(0)" className="text-success" onClick={() => handleModalToggle(true, info.row.original)}>
                            <FaPencil />
                        </a>
                        <a href="javascript:void(0)" className="ms-2 text-danger" onClick={() => handleDeleteModalToggle(true, info.row.original?._id)}>
                            <FaTrashCan />
                        </a>
                    </>;
                },
            },
        ],
        []
    )

    const beforeAPICall = () => {
        return {
            success: true,
            refactoredData: {
                query: {
                    storeId: selectedStore?._id || null,
                    page: currentPage,
                    limit,
                },
            },

        }
    };

    const { handleSubmit: fetchUserData } = useApiRequest({
        apiAction: selectedStore?._id ? FetchStoreUsers : FetchUsers,
        setValueAction: setUserData,
        successMessage: false,
        beforeAPICall,
        isForm: false,
    });

    useEffect(() => {
        fetchUserData();
    }, [currentPage, selectedStore]);

    return (
        <DashboardHOC title={selectedStore?._id ? selectedStore?.userName : "Manage Users"}>
            <div className="inner-wrapper">
                <div className="p-4">
                    <div className="row">
                        <TableSearch apiAction={FetchUsers} setValueAction={setUserData} />

                        <div className="col-auto ms-auto">
                            <Button
                                type="button"
                                onClick={() => handleModalToggle(true, null)}
                            >ADD</Button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            {usersData && <Table
                                data={usersData}
                                columns={columns}
                                currentPage={currentPage}
                                handlePageChange={handlePageChange}
                                totalPages={totalPages}
                            />}
                        </div>
                    </div>
                </div>

                <UserDeleteModal userId={selectedUserId} setUserId={setSelectedUserId} />
                <ManageUserForm defaultData={defaultData} setDefaultData={setDefaultData} />
            </div>
        </DashboardHOC>
    );
};

export default ManageUser;
