import React, { useEffect } from "react";
import { FaStore, FaUser } from "react-icons/fa";
import DashboardHOC from "../../components/hoc/DashboardHOC";
import VerticalChart from "../../components/inner/VerticalChart";
import DashboardStatistics from "../../components/inner/dashboard/DashboardStatistics";
import useApiRequest from "../../store/slices/hook/useApiRequest";
import { useSelector } from "react-redux";
import { FetchDashboardTotalStats, selectDashboardData, setDashboardData } from "../../store/slices/api/inner/dashboardSlice";


export const callsOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    },
};

export const messagesOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },

    },
};


const Dashboard = () => {
    const dashboardData = useSelector(selectDashboardData)

    const callsData = {
        labels: dashboardData?.callGraph?.incoming?.map((item) => item?._id || '') || [],
        datasets: [
            {
                label: 'Incoming Calls',
                data: dashboardData?.callGraph?.incoming?.map((item) => item?.count || 0),
                backgroundColor: 'rgb(111, 205, 205)',
            },
            {
                label: 'Outgoing Calls',
                data: dashboardData?.callGraph?.outgoing?.map((item) => item?.count || 0),
                backgroundColor: 'rgb(255, 215, 120)',
            },
        ],
    };

    const messagesData = {
        labels: dashboardData?.msgGraph?.incoming?.map((item) => item?._id || '') || [],
        datasets: [
            {
                label: 'Incoming Messages',
                data: dashboardData?.msgGraph?.incoming?.map((item) => item?.count || 0),
                backgroundColor: 'rgb(94, 181, 239)',
            },
            {
                label: 'Outgoing Messages',
                data: dashboardData?.msgGraph?.outgoing?.map((item) => item?.count || 0),
                backgroundColor: 'rgb(255, 130, 157)',
            },
        ],
    };

    const { handleSubmit: fetchStoreData } = useApiRequest({
        apiAction: FetchDashboardTotalStats,
        setValueAction: setDashboardData,
        successMessage: false,
        isForm: false,
    });

    useEffect(() => {
        fetchStoreData()
    }, [])

    return (
        <DashboardHOC title="Dashboard">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                                <div className="icon bg-light-primary">
                                    <FaStore className="text-primary" />
                                </div>
                                <div className="dash-con text-end">
                                    <h2>{dashboardData?.totalStores || 0}</h2>
                                    <span>Total Stores</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                                <div className="icon bg-light-orange">
                                    <FaUser className="text-orange" />
                                </div>
                                <div className="dash-con text-end">
                                    <h2>{dashboardData?.totalUser || 0}</h2>
                                    <span>Total Users</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-center">
                                <div className="icon bg-light-green">
                                    <FaStore className="text-green" />
                                </div>
                                <div className="dash-con text-end">
                                    <h2>{dashboardData?.totalCustomers || 0}</h2>
                                    <span>Total Customers</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DashboardStatistics title="Calls" today={dashboardData?.totalCalls?.today || 0} week={dashboardData?.totalCalls?.thisWeek || 0} month={dashboardData?.totalCalls?.thisMonth || 0} all={dashboardData?.totalCalls?.allTime || 0} />
                <DashboardStatistics title="Message" today={dashboardData?.totalMessages?.today || 0} week={dashboardData?.totalMessages?.thisWeek || 0} month={dashboardData?.totalMessages?.thisMonth || 0} all={dashboardData?.totalMessages?.allTime || 0} />

                <div className="col-md-6">
                    <div className="card">
                        <h2 className="text-grey mb-3">Calls</h2>
                        <div className="row">
                            <div className="col-md-12">
                                <VerticalChart options={callsData} data={callsData} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <h2 className="text-grey mb-3">Message</h2>
                        <div className="row">
                            <div className="col-md-12">
                                <VerticalChart options={messagesOptions} data={messagesData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardHOC>
    );
};

export default Dashboard;
