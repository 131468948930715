import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa'
import { FaCirclePlay, FaCloudArrowUp, FaPencil, FaTrashCan } from 'react-icons/fa6'
import Input from '../../ui/Input';
import { AddGreeting, FetchGreetings, selectGreetingData, setGreetingData, UpdateGreeting } from '../../../store/slices/api/inner/greetingSlice';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { useSelector } from 'react-redux';

const SettingsModal = () => {
    const greetingData = useSelector(selectGreetingData);

    const [showGreetingInput, setShowGreetingInput] = useState(false);
    const [showMessageInput, setShowMessageInput] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);

    const { control } = useForm();

    const [isSwitchOnGreet, setIsSwitchOnGreet] = useState(false);
    const [isSwitchOnTell, setIsSwitchOnTell] = useState(false);
    const [isSwitchOnPlay, setIsSwitchOnPlay] = useState(false);

    const handleSwitchChangeGreet = (event) => {
        setIsSwitchOnGreet(event.target.checked);
    };

    const handleSwitchChangeTell = (event) => {
        setIsSwitchOnTell(event.target.checked);
    };

    const handleSwitchChangePlay = (event) => {
        setIsSwitchOnPlay(event.target.checked);
    };

    const toggleGreetingInput = () => {
        setShowGreetingInput(!showGreetingInput);
    }
    const toggleMessageInput = () => {
        setShowMessageInput(!showMessageInput);
    }

    const [greeting, setGreeting] = useState("text");
    const [message, setMessage] = useState("text");

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        setUploadedFile(file);
        console.log(uploadedFile);
        saveFileToLocalStorage(file.name);
    };

    const saveFileToLocalStorage = (file) => {
        try {
            localStorage.setItem('uploadedFile', file);
            console.log('File saved to localStorage:', file);
        } catch (error) {
            console.error('Error saving file to localStorage:', error);
        }
    }

    const { handleSubmit: fetchGreetingData } = useApiRequest({
        apiAction: FetchGreetings,
        setValueAction: setGreetingData,
        successMessage: false,
        isForm: false,
    });

    const beforeAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                query: {
                    greetingId: greetingData?._id,
                },
                formData: {
                    isMusic: isSwitchOnPlay,
                    isAudio: isSwitchOnTell,
                    isGreet: isSwitchOnGreet,
                },
            },
        }
    }

    const { handleSubmit: createGreeting } = useApiRequest({
        apiAction: AddGreeting,
        setValueAction: setGreetingData,
        successMessage: false,
        beforeAPICall,
        isForm: false,
    });

    const { handleSubmit: updateGreeting } = useApiRequest({
        apiAction: UpdateGreeting,
        setValueAction: setGreetingData,
        successMessage: false,
        beforeAPICall,
        isForm: false,
    });

    useEffect(() => {
        fetchGreetingData()
    }, [])

    useEffect(() => {
        setIsSwitchOnGreet(greetingData?.isGreet)
        setIsSwitchOnPlay(greetingData?.isMusic)
        setIsSwitchOnTell(greetingData?.isAudio)
    }, [greetingData])

    useEffect(() => {
        if (greetingData?._id) {
            updateGreeting()
        } else {
            createGreeting()
        }
    }, [isSwitchOnGreet, isSwitchOnPlay, isSwitchOnTell])

    return (
        <div className="modal fade" id="settingsModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Settings</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <FaTimes />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="setting-item">
                            <div className={`d-flex align-items-center justify-content-between`} >
                                <div className="d-flex gap-2 align-items-center">
                                    <img src="/images/greet-icon.svg" alt="" />Greet caller with a message
                                </div>
                                <div className="form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={isSwitchOnGreet} onChange={handleSwitchChangeGreet} />
                                </div>
                            </div>
                            <div className={`changes-section content ${isSwitchOnGreet ? 'enabled' : 'disabled'}`}>
                                <div className="py-3 list">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="form-check mb-0">
                                            <input type="radio" onChange={(e) => setGreeting(e.target.value)} name="greeting" id='greetingMusic' value="music" className="form-check-input" />
                                            <label htmlFor="greetingMusic"> Voice Audio </label>
                                        </div>
                                        <div className="upload">
                                            <div className="text-success">
                                                <input className="form-control" id='formFile2' type="file" hidden disabled={greeting !== "music" && true} onChange={handleFileUpload} />
                                                <label htmlFor="formFile2" className={`mb-0 ${greeting === "music" && "text-success"}`}>
                                                    <FaCloudArrowUp /> Upload </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 text-grey ps-4 mt-2">
                                        <FaCirclePlay /> Loreum Ipsum.mp3 <a href="javascript:void(0)" className="text-danger">
                                            <FaTrashCan />
                                        </a>
                                    </div>
                                </div>
                                <div className="divider"></div>

                                <div className="py-3 list">
                                    <div className="d-flex align-items-center justify-content-between editable-container">
                                        <div className="form-check mb-0 d-flex">
                                            <input type="radio" name="greeting" onChange={(e) => setGreeting(e.target.value)} value="text" className="form-check-input" id="greetingText" />
                                            {showGreetingInput ? <Input
                                                control={control}
                                                name="greeting"
                                                type="text"
                                                rules={{
                                                    required: "this filed is required"
                                                }}
                                            /> : <label htmlFor="greetingText" className={`text-primary mb-0 editable-label`}> Please hold while we connect your call </label>}

                                        </div>
                                        <div className="edit">
                                            <button className={`edit-button text-grey p-0 m-0 border-0 bg-white ${greeting === "text" && "text-success"}`} disabled={greeting !== "text" && true} onClick={toggleGreetingInput}>
                                                <FaPencil /> Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="setting-item mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <img src="/images/volume-icon.svg" alt="" />Tell caller they’re being connected
                                </div>
                                <div className="form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={isSwitchOnTell} onChange={handleSwitchChangeTell} />
                                </div>
                            </div>
                            <div className={`changes-section content ${isSwitchOnTell ? 'enabled' : 'disabled'}`}>
                                <div className="py-3 list">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="form-check mb-0">
                                            <input type="radio" name="message" onChange={(e) => setMessage(e.target.value)} value="music" id="messageMusic" className="form-check-input" />
                                            <label htmlFor="messageMusic"> Voice Audio </label>
                                        </div>
                                        <div className="upload">
                                            <div className="text-success">
                                                <input className="form-control" id="formFile3" type="file" hidden disabled={message !== "music" && true} onChange={handleFileUpload} />
                                                <label htmlFor="formFile3" className={`mb-0 ${message === "music" && "text-success"}`}>
                                                    <FaCloudArrowUp /> Upload </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 text-grey ps-4 mt-2">
                                        <FaCirclePlay /> No audio file
                                    </div>
                                </div>
                                <div className="divider"></div>

                                <div className="py-3 list">
                                    <div className="d-flex align-items-center justify-content-between editable-container">
                                        <div className="form-check mb-0 d-flex">
                                            <input type="radio" name="message" value="text" onChange={(e) => setMessage(e.target.value)} className="form-check-input" id="messageText" />

                                            {showMessageInput ? <Input
                                                control={control}
                                                name="message"
                                                type="text"
                                                rules={{
                                                    required: "this filed is required"
                                                }}
                                            /> : <label htmlFor="messageText" className={`text-primary mb-0 editable-label`}> Please hold while we connect your call </label>}

                                        </div>
                                        <div className="edit">
                                            <button className={`edit-button text-grey p-0 m-0 border-0 bg-white ${message === "text" && "text-success"}`} disabled={message !== "text" && true} onClick={toggleMessageInput}>
                                                <FaPencil /> Edit
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="setting-item mt-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex gap-2 align-items-center">
                                    <img src="/images/music-icon.svg" alt="" /> Play music while connecting
                                </div>
                                <div className="form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" checked={isSwitchOnPlay} onChange={handleSwitchChangePlay} />
                                </div>
                            </div>
                            <div className={`changes-section content ${isSwitchOnPlay ? 'enabled' : 'disabled'}`}>
                                <div className="py-3">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="mb-0">
                                            <label>Music</label>
                                        </div>
                                        <div className="upload">
                                            <div className="text-success">
                                                <input className="form-control text-success" id='formFile1' type="file" hidden />
                                                <label htmlFor="formFile1" className="text-success mb-0">
                                                    <FaCloudArrowUp /> Upload </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-2 text-grey mt-2">
                                        <FaCirclePlay /> Loreum Ipsum.mp3 <a href="javascript:void(0)" className="text-danger">

                                            <FaTrashCan />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {uploadedFile && (
                            <div className="d-flex align-items-center gap-2 text-grey ps-4 mt-2">
                                Select File {uploadedFile.name}{" "}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsModal