import React, { useEffect, useState } from 'react';
import Button from '../../ui/Button';
import { FaTimes } from 'react-icons/fa';
import useApiRequest from '../../../store/slices/hook/useApiRequest';
import { FetchCustomers, selectCustomerData, setCustomerData } from '../../../store/slices/api/inner/customerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AddContact, addContactData, setSelectedContact } from '../../../store/slices/api/inner/conversationSlice';
import { selectLoggedInData } from '../../../store/slices/api/auth/loginSlice';
import { setNewMessageModal } from '../../../store/slices/misc/commonStatesSlices';

const NewMessageModal = () => {
    const customersData = useSelector(selectCustomerData);
    const dispatch = useDispatch();
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const loggedInData = useSelector(selectLoggedInData);
    const modalState = useSelector(state => state.common.newMessageModal)

    const handleModalToggle = (value) => {
        dispatch(setNewMessageModal(value))
    };
    const { handleSubmit: fetchCustomerData } = useApiRequest({
        apiAction: FetchCustomers,
        setValueAction: setCustomerData,
        successMessage: false,
        isForm: false,
    });

    useEffect(() => {
        if (!customersData) {
            fetchCustomerData();
        }
    }, [customersData]);

    const afterAPICall = (response) => {
        dispatch(setSelectedContact(response?.data));
        handleModalToggle(false)
    };


    // button click
    const beforeAPICall = (formData) => {
        return {
            success: true,
            refactoredData: {
                formData: {
                    customerId: selectedCustomerId,
                    // userId: loggedInData?._id,
                    userId: loggedInData?.role === "admin" ? "66794eea8cef50fc5cd5cbd6" : loggedInData?._id,
                },
            },
        };
    };

    const { isLoading, handleSubmit } = useApiRequest({
        apiAction: AddContact,
        setValueAction: addContactData,
        isForm: false,
        successMessage: false,
        afterAPICall,
        beforeAPICall,
    });

    const handleCustomerSelection = (customerId) => {
        setSelectedCustomerId(customerId);
    };

    return (
        modalState ? <>
            <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">New Message</h5>
                            <button type="button" className="btn-close" onClick={() => handleModalToggle(false)} aria-label="Close">
                                <FaTimes />
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="search-head">
                                <input type="text" className="form-control" placeholder="Search" />
                            </div>
                            <div className="main-list px-4 py-3">
                                <ul>
                                    {customersData &&
                                        customersData.map((customer) => (
                                            <li key={customer._id} onClick={() => handleCustomerSelection(customer._id)} style={{ cursor: 'pointer' }}>
                                                <div className="con">
                                                    <div className="img">
                                                        <img src="/images/user-img.png" alt="" />
                                                    </div>
                                                    <div className="txt">
                                                        <span>
                                                            {customer?.firstName} {customer?.lastName}
                                                        </span>
                                                        <small className="text-grey">{customer?.phone}</small>
                                                    </div>
                                                </div>
                                                <div className="option">
                                                    <div className="form-check mb-0">
                                                        <input
                                                            type="radio"
                                                            name="customerOption"
                                                            value={customer._id}
                                                            className="form-check-input"
                                                            checked={selectedCustomerId === customer._id}
                                                            onChange={() => handleCustomerSelection(customer._id)}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button type="button" className="btn-w" onClick={handleSubmit}>
                                Done
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal-backdrop fade show`} onClick={() => handleModalToggle(false)}></div>
        </> : null
    );
};

export default NewMessageModal;
