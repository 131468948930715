import React from 'react'
import Header from '../common/Header'
import Sidebar from '../common/Sidebar'
import { NAVIGATION } from '../router/Routes'
import { Link, useLocation } from 'react-router-dom'
import LogoutModal from '../common/LogoutModal'
import UpdateProfileModal from '../common/UpdateProfileModal'
import ChangePasswordModal from '../common/ChangePasswordModal'

const DashboardHOC = ({ title, children }) => {
    const { pathname } = useLocation();
    return (
        <div>
            <Sidebar />
            <main>
                <Header />
                <div className="wrapper">
                    <div className="head mb-4">
                        <ol className="breadcrumb gap-2">
                            <li className="breadcrumb-item">
                                <Link to={NAVIGATION.INNER_NAVIGATION.DASHBOARD}>Dashboard</Link>
                            </li>
                            <span> {'>'} </span>
                            <li className="breadcrumb-item active">{pathname === "/inner/dashboard" ? "default" : title}</li>
                        </ol>
                        <h1>{title}</h1>
                    </div>

                    {children}
                </div>
            </main>

            <LogoutModal />
            <UpdateProfileModal />
            <ChangePasswordModal />
        </div>
    )
}

export default DashboardHOC